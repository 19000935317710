/* eslint-disable camelcase */
import { GTMItem, GTMOrderItem, Order } from "../types";

const parseEventOrderItemToGtmItem = (item: any, i: number): GTMOrderItem => {
  const price = parseInt(item.price.amount) / 100;
  const item_id = item.characteristics.description.id;
  const item_category = item.characteristics.description
    ? item.characteristics.description.category
    : "giftcard";
  const item_brand = item.characteristics.supplier
    ? item.characteristics.supplier.name
    : "konfetti";
  const item_variant =
    item.characteristics?.description?.type === "NATIONWIDE"
      ? "KIT"
      : item?.type;

  return {
    index: i,
    item_id: item_id,
    item_name: item?.characteristics?.description?.title || item.title,
    item_category: item_category,
    item_brand: item_brand,
    quantity: item.quantity,
    item_variant: item_variant,
    price: price?.toFixed(2) || price,
  };
};
const parseGiftcardOrderItemToGtmItem = (
  item: any,
  i: number,
): GTMOrderItem => {
  const price = parseInt(item.price.amount) / 100;
  const item_id = item.characteristics?.description?.id
    ? item.characteristics?.description?.id
    : "giftcard-" + price;
  const item_category = "giftcard";

  const item_brand = item.characteristics?.supplier
    ? item.characteristics.supplier.name
    : "konfetti";

  return {
    index: i,
    item_id: item_id,
    item_name: item?.characteristics?.description?.title || item.title,
    item_category: item_category,
    item_brand: item_brand,
    quantity: item.quantity,
    item_variant: item?.type,
    price: price?.toFixed(2) || price,
  };
};

const parseAddOnOrderItemToGtmItem = (item: any, i: number): GTMOrderItem => {
  const price = parseInt(item.price.amount) / 100;
  const item_brand = item.characteristics?.supplier
    ? item.characteristics.supplier.name
    : "konfetti";

  return {
    index: i,
    item_id: `addon-${item.characteristics.id}`,
    item_name: item.title,
    item_category: "ADD_ON",
    item_brand: item_brand,
    quantity: item.quantity,
    item_variant: item?.type,
    price: price?.toFixed(2) || price,
  };
};

const parseOrderItemToGtmItem = (item: any, index: number): GTMOrderItem => {
  const types = {
    ADD_ON: parseAddOnOrderItemToGtmItem,
    EVENT: parseEventOrderItemToGtmItem,
    PRIVATE_EVENT: parseEventOrderItemToGtmItem,
    GIFT_CARD: parseGiftcardOrderItemToGtmItem,
  };

  return types[item.type]?.(item, index) || {};
};

const isValueBasedGiftcard = (item: any): boolean => {
  return item.type === "GIFT_CARD" && !item.characteristics?.description?.id;
};

/** @name parseItemsForGtm
 ** @desc As the name states, it will parse an order items to push the "purchase" event to GTM */
export const parseItemsForGtm = (order: Order): GTMOrderItem[] => {
  const items = [];
  let giftbox_price = 0;
  let giftbox_quantity = 0;

  for (let i = 0; i < order?.items?.data?.length || 0; i++) {
    const item = order.items.data[i];
    if (item.characteristics.gift_box) {
      if (isValueBasedGiftcard(item)) {
        giftbox_quantity += item.quantity;
        // incrementing price, but it will be divided by quantity later to get unit price
        giftbox_price +=
          (item.characteristics.gift_box.price.amount / 100) * item.quantity;
      } else {
        giftbox_quantity += 1;
        // incrementing price, but it will be divided by quantity later to get unit price
        giftbox_price += item.characteristics.gift_box.price.amount / 100;
      }
    }

    items.push(parseOrderItemToGtmItem(item, i));
  }

  // add the gift box object in the purchase event after all other items
  if (giftbox_quantity > 0) {
    items.push({
      index: order?.items?.data?.length || "",
      item_id: "gift-box",
      item_name: "Gift Box",
      item_category: "Gift Box",
      quantity: giftbox_quantity,
      type: "GIFT_BOX",
      price: (giftbox_price / giftbox_quantity).toFixed(2),
    });
  }
  return items;
};

export const getId = (order: Order): string => {
  return order.id;
};

export const getPaymentGateway = (order: Order): string => {
  return order.payment_gateway;
};

export const getStatus = (order: Order): string => {
  return order.status;
};

export const getCreatedAt = (order: Order): string => {
  return order.created_at;
};

export const getFormattedTotal = (order: Order): string => {
  return order.total.formatted;
};

export const getShaEmailAddress = (order: Order): string => {
  return order.user?.data?.sha256_email_address;
};

export const getCountryCode = (order: Order): string => {
  return order.country_code || "DE";
};

export const orderGetters = {
  getId,
  getPaymentGateway,
  parseItemsForGtm,
  getStatus,
  getCreatedAt,
  getFormattedTotal,
  getShaEmailAddress,
  getCountryCode,
};
