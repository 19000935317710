import type { Ref } from "@vue/composition-api";
import dayjs from "dayjs";

const beforeNYears = (
  form: Ref<any>,
  key: string,
  numberOfYears: string,
): boolean => {
  return dayjs(form.value[key], "YYYY-MM-DD").isBefore(
    dayjs().subtract(parseInt(numberOfYears), "year"),
  );
};

export default beforeNYears;
