import { watch } from "@nuxtjs/composition-api";
import { CartKeys, Error400 } from "@konfetti/composables/src";
import { useCartValidation } from "../../useCartValidation";
import { ErrorStoreErrorType, useErrorStore } from "../../useErrorStore";
import { useCartValidationErrorHandler } from "../useCartValidationErrorHandler";
import { useOrderErrorHandler } from "../useOrderErrorHandler";

/*
  This composable is called "useDefaultErrorHandlers" and it provides default
  error handling functionality for our application. It utilizes
  various other composable functions to register global error in the error store
  handlers and watch for changes in global error shared refs, then send it
  to the error store.

  The purpose of this composable is to provide a default error handling for
  errors that are saved in the error store all over the application.
 */
export function useDefaultErrorHandlers() {
  const { handle: handleCartValidationError } = useCartValidationErrorHandler();
  const { handle: handleOrderError } = useOrderErrorHandler();
  const { registerGlobalErrorHandler } = useErrorStore();
  const { cartItemsValidationError } = useCartValidation(CartKeys.GENERAL);
  const { handleError } = useErrorStore();

  const registerDefaultErrorHandlers = () => {
    // CartValidationError
    registerGlobalErrorHandler(
      ErrorStoreErrorType.CartValidationError,
      handleCartValidationError,
    );

    // Order Errors
    Object.keys(ErrorStoreErrorType).forEach((key) => {
      registerGlobalErrorHandler(ErrorStoreErrorType[key], handleOrderError);
    });
  };

  // Watching global error sharedRefs to
  // send to the error store only once
  watch(
    cartItemsValidationError,
    (newValue: Error400) => {
      handleError({
        type: ErrorStoreErrorType.CartValidationError,
        error: newValue,
      });
    },
    { deep: true },
  );

  return {
    registerDefaultErrorHandlers,
  };
}
