/* eslint-disable camelcase */
import { UseKftContext } from "@konfetti-core/core";
import {
  useCart,
  cartGetters,
  eventGetters,
  orderGetters,
} from "@konfetti/composables";
import {
  CartItem,
  CategoryItem,
  EventDescription,
} from "@konfetti/composables/src";
import { getUnitItemPrice } from "@konfetti/composables/src/getters/cartGetters";
import { useBooking } from "../useBooking";
import { sendViewItemListConfig } from "./sendViewItemListConfig";
import { GtmItemListId } from "./types";
import { useEmbeddedCheckout } from "~/composables";

export const useGtm = (): any => {
  const { isEmbedded } = useEmbeddedCheckout();
  /* Cart */
  const { cart } = useCart(
    isEmbedded.value ? "embedded-instance" : "general-instance",
  );

  const { $gtm } = UseKftContext();
  const { getEventPrice } = useBooking("use-gtm");

  const sendAddToCart = (
    event: any,
    isGiftcard: boolean,
    cartItem: CartItem,
    cartItemQty?: number,
  ) => {
    if (!$gtm) {
      return;
    }

    const gtmPrice =
      getUnitItemPrice(cartItem) ||
      parseFloat(getEventPrice(event, true)) / 100;

    const eventQty = cartItemQty || cartItem.quantity;

    // GA4 object
    $gtm.push({ ecommerce: null });
    $gtm.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        items: [
          {
            item_name: cartGetters.getItemName(cartItem) || "giftcard",
            id: cartGetters.getItemIdForGtm(cartItem),
            price: parseFloat(gtmPrice?.toFixed(2)) || gtmPrice,
            item_brand: eventGetters.getSupplierName(event?.data) || "",
            item_category: cartGetters.getItemMainCategory(cartItem),
            item_variant: cartGetters.getItemVariantForGtm(cartItem),
            quantity: eventQty,
          },
        ],
      },
    });
  };

  const sendRemoveFromCart = (product: any, cartItemQty?: number) => {
    if (!$gtm) {
      return;
    }

    $gtm.push({ ecommerce: null });
    $gtm.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "EUR",
        value: cartGetters.getTotals(product).total,
        items: [
          {
            item_name:
              (cartGetters.isGiftcard(product) ? "giftcard | " : "") +
              cartGetters.getItemName(product),
            item_id: product.eventDescriptionId,
            price: parseFloat(cartGetters.getUnitItemPrice(product).toFixed(2)),
            item_category: cartGetters.getItemMainCategory(product),
            quantity: cartItemQty || cartGetters.getItemQty(product),
          },
        ],
      },
    });
  };

  const sendCheckout = (cart: any, step: number) => {
    if (!$gtm) {
      return;
    }

    // here we're handling the new Checkout events for GA4
    // since we're handling the login/signup seperatly,
    // we can ignore the former step 2
    if (step === 2) return;

    let eventName: string;
    switch (step) {
      case 1: {
        eventName = "begin_checkout";
        break;
      }
      case 3: {
        eventName = "add_payment_info";
        break;
      }
    }
    setTimeout(() => {
      $gtm.push({ ecommerce: null });
      $gtm.push({
        event: eventName,
        ecommerce: {
          currency: "EUR",
          value: cartGetters.getTotals(cart).total,
          items: cartGetters.getItemsForGtm(cart),
        },
      });
    }, 1200);
  };

  const sendViewLogin = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "view_login",
    });
  };

  const sendViewSignup = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "view_signup",
    });
  };

  const sendLogin = (method: string, user_id) => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "login",
      method,
      user_id,
    });
  };

  const sendSignup = (method: string, user_id) => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "signup",
      method,
      user_id,
    });
  };

  const sendLogout = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "logout",
      user_id: "",
    });
  };

  const sendViewCart = () => {
    if (!$gtm) {
      return;
    }
    $gtm.push({ ecommerce: null });
    $gtm.push({
      event: "view_cart",
      ecommerce: {
        currency: "EUR",
        value: cartGetters.getTotals(cart.value).total,
        items: cartGetters.getItemsForGtm(cart.value),
      },
    });
  };

  const sendOrderPurchase = (order: any, revenue?: number) => {
    if (!$gtm) {
      return;
    }

    setTimeout(() => {
      $gtm.push({ ecommerce: null });
      $gtm.push({
        event: "purchase_ga4",
        sha256_email: orderGetters.getShaEmailAddress(order.data),
        country_code: orderGetters.getCountryCode(order.data),
        ecommerce: {
          currency: "EUR",
          value: revenue?.toFixed(2),
          transaction_id: order.data?.id,
          coupon: order.data?.coupon?.data?.code
            ? order.data.coupon.data.code
            : "",
          marketing_campaign_id:
            order?.data?.coupon?.data?.mkt_campaign_id || "",
          items: orderGetters.parseItemsForGtm(order.data),
        },
      });
    }, 1200);
  };

  const sendCartPurchase = (order: any, coupon: any, cart: any) => {
    if (!$gtm) {
      return;
    }
    setTimeout(() => {
      $gtm.push({ ecommerce: null });
      $gtm.push({
        event: "purchase_ga4",
        sha256_email: orderGetters.getShaEmailAddress(order.data),
        country_code: orderGetters.getCountryCode(order.data),
        ecommerce: {
          currency: "EUR",
          transaction_id: order?.data?.id || null,
          value: cartGetters.getTotals(cart).total,
          coupon: order?.data?.coupon?.data?.code || "",
          marketing_campaign_id:
            order?.data?.coupon?.data?.mkt_campaign_id || "",
          items: orderGetters.parseItemsForGtm(order.data),
        },
      });
    }, 1200);
  };

  const sendProductDetailView = (event: any) => {
    if (!$gtm) {
      return;
    }

    const gtmPrice = parseFloat(getEventPrice(event, true)) / 100;
    setTimeout(() => {
      $gtm.push({ ecommerce: null });
      $gtm.push({
        event: "view_item",
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_id: eventGetters.getId(event.data),
              item_brand: eventGetters.getSupplierName(event.data),
              item_name: eventGetters.getTitle(event.data),
              item_category: eventGetters.getMainCategory(event.data),
              price: gtmPrice?.toFixed(2) || gtmPrice,
              quantity: 1,
            },
          ],
        },
      });
    }, 1200);
  };

  const sendViewItemList = (
    itemListId: GtmItemListId,
    items: (EventDescription | CategoryItem)[],
  ) => {
    if (!$gtm || !itemListId) {
      return;
    }

    const { listName, parser } = sendViewItemListConfig[itemListId];

    const itemListData = {
      item_list_id: itemListId,
      item_list_name: listName,
    };

    const parsedItems = items.map((item, index) => ({
      ...parser(item),
      ...itemListData,
      index,
    }));
    setTimeout(() => {
      $gtm.push({ ecommerce: null });
      $gtm.push({
        event: "view_item_list",
        ecommerce: {
          ...itemListData,
          items: parsedItems,
          currency: "EUR",
        },
      });
    }, 1200);
  };

  const sendSelectListItem = (
    itemListId: GtmItemListId,
    items: (EventDescription | CategoryItem)[],
    i: number,
  ) => {
    if (!$gtm || !itemListId) {
      return;
    }

    const { listName, parser } = sendViewItemListConfig[itemListId];

    const itemListData = {
      item_list_id: itemListId,
      item_list_name: listName,
    };

    const parsedItems = items.map((item) => ({
      ...parser(item),
      ...itemListData,
      index: i,
    }));

    $gtm.push({ ecommerce: null });
    $gtm.push({
      event: "select_item",
      ecommerce: {
        ...itemListData,
        items: parsedItems,
        currency: "EUR",
      },
    });
  };

  const sendGalleryShareIntent = (event: any, type: string) => {
    if (!$gtm) {
      return;
    }
    $gtm.push({
      event: "share_event_modal",
      event_id: eventGetters.getId(event),
      event_name: eventGetters.getTitle(event),
      sharing_type: type,
    });
  };

  const sendGalleryShareOption = (event: any, type: string) => {
    if (!$gtm) {
      return;
    }
    $gtm.push({
      event: "share_event",
      event_id: eventGetters.getId(event),
      event_name: eventGetters.getTitle(event),
      sharing_options: type,
    });
  };

  const sendGalleryShareNativeStatus = (event: any, status: string) => {
    if (!$gtm) {
      return;
    }
    $gtm.push({
      event: "share_event_status",
      event_id: eventGetters.getId(event),
      event_name: eventGetters.getTitle(event),
      sharing_type: "native",
      sharing_status: status,
    });
  };

  const sendWaitlistForm = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "submit_waitlist",
    });
  };

  const sendDateRequestForm = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "submit_daterequest",
    });
  };

  const sendDateRequestSidebarView = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "view_daterequest_sidebar",
    });
  };

  const sendWaitlistSidebarView = () => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "view_waitlist_sidebar",
    });
  };

  const sendPricingOptions = (option: string) => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "select_pricing_plan",
      pricing_option: option,
    });
  };

  const sendSocialLoginAttempt = (method: "facebook" | "google") => {
    if (!$gtm) {
      return;
    }

    $gtm.push({
      event: "social_login_attempt",
      method,
    });
  };

  return {
    sendAddToCart,
    sendProductDetailView,
    sendOrderPurchase,
    sendCartPurchase,
    sendCheckout,
    sendRemoveFromCart,
    sendViewCart,
    sendViewLogin,
    sendViewSignup,
    sendLogin,
    sendSignup,
    sendLogout,
    sendViewItemList,
    sendSelectListItem,
    sendGalleryShareIntent,
    sendGalleryShareOption,
    sendGalleryShareNativeStatus,
    sendWaitlistForm,
    sendDateRequestForm,
    sendDateRequestSidebarView,
    sendPricingOptions,
    sendWaitlistSidebarView,
    sendSocialLoginAttempt,
  };
};
