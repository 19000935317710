import { withTrailingSlash } from "../helpers/url";

export default (ctx) => {
  const { route, redirect, $config, $fullLocalizedUrl, $ssrContext } = ctx;
  const to = route;

  const noLocaleWhitelist = ["/sitemap-.*"];

  const whitelist = [
    "/account",
    "/account/general-info",
    "/account/orders",
    "/account/referral-info",
    "/account/user-info",
    "/auth/facebook/callback",
    "/auth/google/callback",
    "/checkout",
    "/embedded/.*",
    "/login",
    "/login/forgot-password",
    "/order-success",
    "/password-reset",
    "/ping",
    "/sitemap",
    "/stripe-3d-auth-complete",
  ];

  const transformedPath = (path) => path.replace(/:\w+\(\)/g, ".*");

  const toPathWithoutLocale = withTrailingSlash(
    to.path.replace(/^\/[a-z]{2}-[a-z]{2}\//, "/"),
  );

  const isRouteWhitelisted = [...whitelist, ...noLocaleWhitelist].some((path) =>
    toPathWithoutLocale.match(transformedPath(path)),
  );

  const ssrHost = ctx.ssrContext?.req?.headers?.host
    ?.split(".")
    .filter((x) => x !== "www")
    .join(".");

  const currentHost = ssrHost || window.location.host;
  const newUrl = new URL($fullLocalizedUrl(toPathWithoutLocale));

  newUrl.host = currentHost;

  if (!isRouteWhitelisted) {
    if ($config.nodeEnv === "development") {
      newUrl.host = "gokonfetti.com";
      newUrl.port = "";
    }
    return redirect(newUrl.href);
  }
};
