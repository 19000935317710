export const head = {
  title: "konfetti",
  meta: [
    { charset: "utf-8" },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    {
      name: "theme-color",
      content: "#026166",
    },
    {
      hid: "description",
      name: "description",
      content:
        "Finde Kurse und Erlebnisse in Deiner Stadt und Online. Für Dich, als Geschenkgutschein oder Teamevents - bei konfetti unkompliziert buchen.",
    },
    {
      hid: "og:description",
      name: "og:description",
      content:
        "Finde Kurse und Erlebnisse in Deiner Stadt und Online. Für Dich, als Geschenkgutschein oder Teamevents - bei konfetti unkompliziert buchen.",
    },
  ],
  link: [
    {
      rel: "preconnect",
      href: process.env.API_BASE_URL || "https://api.gokonfetti.com/",
      crossOrigin: true,
    },
    { rel: "preconnect", href: "https://dev.visualwebsiteoptimizer.com" },
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.svg",
    },

    /* Testing: Woff fonts are not being preloaded  */

    /** Lexend
     ** -------------------------- */
    {
      // once: true,
      rel: "preload",
      type: "font/woff2",
      as: "font",
      href: "/_nuxt/static/fonts/lexend/lexend-v16-latin-regular.woff2",
      crossOrigin: true,
      display: "swap",
    },
    {
      // once: true,
      rel: "preload",
      type: "font/woff2",
      as: "font",
      href: "/_nuxt/static/fonts/lexend/lexend-v16-latin-500.woff2",
      crossOrigin: true,
      display: "swap",
    },
    {
      // once: true,
      rel: "preload",
      type: "font/woff2",
      as: "font",
      href: "/_nuxt/static/fonts/lexend/lexend-v16-latin-600.woff2",
      crossOrigin: true,
      display: "swap",
    },

    {
      // once: true,
      rel: "preload",
      type: "font/woff2",
      as: "font",
      href: "/_nuxt/static/fonts/bootstrap/bootstrap-icons.woff2",
      crossOrigin: true,
      display: "swap",
    },
  ],
};
