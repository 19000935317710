













































































































































































































































































































































import { KftBadge, KftButton, KftSidebar, KftLink } from "@konfetti-ui/vue";
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";

import { cartGetters, useCart, useUserComplete } from "@konfetti/composables";
import { KftHeader, KftHeaderNavigationItem } from ".";
import AlgoliaFederatedSearch from "~/components/molecules/AlgoliaFederatedSearch/AlgoliaFederatedSearch.vue";
import { withTrailingSlash } from "~/helpers/url";
import useUiState from "~/composables/useUiState";
import ContactForm from "~/components/ContactForm.vue";
import { MenuComponent } from "~/plugins/metronic/components/MenuComponent";

export default {
  components: {
    AlgoliaFederatedSearch,
    KftHeader,
    KftHeaderNavigationItem,
    KftSidebar,
    KftButton,
    KftBadge,
    ContactForm,
    KftLink,
  },

  props: {
    logo: {
      type: String,
      default: "/logos/full-logo-color-black.svg",
    },
    useIcons: {
      type: Boolean,
      default: false,
    },
    showingSearch: {
      type: Boolean,
      default: false,
    },
    supplierPermalink: {
      type: String,
      default: null,
    },
    shouldEnableGiftcard: {
      type: Boolean,
      default: true,
    },
  },

  setup(
    props,
    { root: { $i18n, $route, localePath, $router, $cookies, $kft } },
  ) {
    const {
      isNavigationSidebarOpen,
      toggleNavigationSidebar,
      toggleCartSidebar,
    } = useUiState();
    const displayAccountDropdown = ref(false);

    const { logout, isAuthenticated } = useUserComplete("general-instance");

    const { cart, loadCart } = useCart("general-instance");
    const contactMenu = ref(null);
    const cartTotalItems = computed(() => {
      const count = cartGetters.getTotalItems(cart.value);
      return count > 0 ? count.toString() : null;
    });

    const isBookingSolution = computed(() => Boolean(props.supplierPermalink));
    const contactForm = ref(null);

    const handleClickContactUs = () => {
      contactForm.value.init();
    };

    const handlePhone = () => {
      window.location.href = $i18n.t("general.contactPhoneAction");
    };

    const handleEmail = () => {
      window.location.href = `mailto:${$i18n.t("general.contactEmail")}`;
    };

    const toggleAccountDropdown = () => {
      displayAccountDropdown.value = true;
    };

    const removeLocaleCookies = () => {
      // This ensures the correct cookie is sended to the next request
      const cookiesToRemove = ["kft-currency", "kft-locale", "kft-country"];

      cookiesToRemove.forEach((key) => {
        $cookies.remove(key);
      });
    };

    const refreshAccountDropdown = () => {
      // we can maybe go into the implementation and understand what the below
      // function does behind the hood
      MenuComponent.reinitialization();
    };

    const handleLogout = async () => {
      await logout();
      const isInAccountArea =
        $route.matched.length > 0 &&
        ($route.matched[0].path === localePath("/account") ||
          $route.matched[0].path === localePath("/login"));

      if (isInAccountArea) {
        $router.push(localePath("/"));
      }
    };

    const user = ref(null);
    onMounted(async () => {
      refreshAccountDropdown();

      if (!isAuthenticated.value) {
        return;
      }
      user.value = $kft.$auth?.config?.getUser();
      await nextTick();

      await loadCart();
    });

    return {
      isAuthenticated,
      logoImage: computed(
        () => props.logo || "/logos/full-logo-color-black.svg",
      ),
      handleLogout,
      displayAccountDropdown,
      cartTotalItems,
      toggleCartSidebar,
      contactMenu,
      isNavigationSidebarOpen,
      toggleNavigationSidebar,
      contactForm,
      withTrailingSlash,
      isBookingSolution,

      removeLocaleCookies,
      toggleAccountDropdown,
      handleClickContactUs,
      handlePhone,
      handleEmail,
    };
  },
};
