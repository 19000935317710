<template>
  <div class="checkout-success" data-test-id="checkout-success">
    <!-- BEGIN::ORDER SURVEY -->
    <div
      v-show="showSurvey && !surveyAnswered && !isEmbedded"
      class="checkout-success__survey card shadow-sm margin-auto w-100 mb-8 mt-8"
    >
      <div class="card-header">
        <KftHeading
          :level="4"
          :title="$t('checkout.surveyTitle')"
          class="lc-block mt-8 mb-6"
        />
      </div>
      <div class="card-body">
        <div class="checkout-success__survey-options row row-cols-2">
          <KftCheckbox
            v-for="(option, i) in options"
            :key="`survey-item-${i}`"
            :label="option.label"
            :selected="selectedOption === option.value"
            :value="option.value"
            class="my-2"
            @change="surveyOptionSelected(option.value)"
          />
        </div>

        <template v-if="selectedOption === 'OTHER'">
          <label class="form-label mt-8" for="other-input">{{
            $t("survey.otherInputLabel")
          }}</label>
          <input
            id="other-input"
            v-model="otherInput"
            class="form-control"
            name="other-input"
            @keydown.enter.prevent="submit"
          />
        </template>
      </div>
      <div class="card-footer">
        <button
          :disabled="loading"
          class="btn btn-primary btn-shadow w-100"
          @click="submit"
        >
          {{ $t("general.submit") }}
        </button>
      </div>
    </div>
    <!-- END::ORDER SURVEY -->

    <!-- BEGIN::ORDER SUCCESS -->
    <div class="card p-6 d-flex flex-column align-items-center">
      <KftHeading
        :level="4"
        :title="
          processedOrder
            ? $t('checkout.success.title')
            : $t('checkout.pending.title')
        "
        class="lc-block mb-6 text-center"
      >
        <template #description>
          <div class="row justify-content-center">
            <span class="mt-2 col-12">
              {{ $t("checkout.success.orderNo") }}&nbsp;
              <strong>{{ $route.query.id || orderId }}</strong>
            </span>
            <p class="mt-2 col-10">{{ $t("checkout.success.copy") }}</p>
          </div>
        </template>
      </KftHeading>

      <button
        class="btn btn-primary btn-shadow w-100 mt-5"
        @click="$router.push(localePath('/'))"
      >
        {{ $t("checkout.success.homeButton") }}
      </button>
      <client-only v-if="userReferralStatusIsActive">
        <ReferralInfoUserCode
          :title="$t('checkout.success.referralInfo.title')"
          class="w-100 mt-7 checkout-success__referral-info"
        />
      </client-only>
    </div>
    <!-- END::ORDER SUCCESS -->
  </div>
</template>

<script>
import { KftHeading } from "@konfetti-ui/vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import { useOrder, useUserComplete, userGetters } from "@konfetti/composables";
import VsToast from "@vuesimple/vs-toast";
import LazyHydrate from "vue-lazy-hydration";
import KftCheckbox from "./molecules/KftCheckbox/KftCheckbox.vue";
import { useEmbeddedCheckout } from "~/composables";
import ReferralInfoUserCode from "~/components/pages/account/referral-info/ReferralInfoUserCode";

export default {
  name: "CheckoutSuccess",

  components: {
    KftHeading,
    KftCheckbox,
    ReferralInfoUserCode,
    LazyHydrate,
  },

  props: {
    order: {
      type: Object,
    },
    processedOrder: {
      type: Boolean,
      default: true,
    },
    homeLink: {
      type: Boolean,
      default: true,
    },
    showSurvey: {
      type: Boolean,
      default: false,
    },
    hasAccessToWindow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { root: { $i18n } }) {
    const { user } = useUserComplete("general-instance");

    const selectedOption = ref(null);
    const otherInput = ref(null);
    const { sendOrderSurvey, loading } = useOrder(
      "checkout-success-component",
      props.hasAccessToWindow,
    );
    const { isEmbedded } = useEmbeddedCheckout();
    const surveyAnswered = ref(false);
    const explosionHappened = ref(false);
    const options = ref([
      {
        value: "INSTAGRAM",
        label: $i18n.t("survey.instagram"),
      },
      {
        value: "TIKTOK",
        label: $i18n.t("survey.tiktok"),
      },
      {
        value: "FACEBOOK",
        label: $i18n.t("survey.facebook"),
      },
      {
        value: "PINTEREST",
        label: $i18n.t("survey.pinterest"),
      },
      {
        value: "GOOGLE_BING",
        label: $i18n.t("survey.googleBing"),
      },
      {
        value: "BLOG_MAGAZINE",
        label: $i18n.t("survey.blogMagazine"),
      },
      {
        value: "FRIEND",
        label: $i18n.t("survey.friend"),
      },
      {
        value: "OTHER",
        label: $i18n.t("survey.other"),
      },
    ]);

    const userReferralStatusIsActive = computed(() => {
      return user.value?.data
        ? userGetters.getReferralCouponCode(user.value.data)?.data?.status ===
            "ACTIVE"
        : false;
    });

    const shuffle = (array) => {
      let currentIndex = array.length;
      let randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    };

    const surveyOptionSelected = (value) => {
      selectedOption.value = value;
    };

    const submit = () => {
      if (!props.showSurvey) {
        return;
      }

      const surveyForm = {
        id: props?.order?.data?.id,
        // eslint-disable-next-line camelcase
        where_heard_about_konfetti: selectedOption.value,
      };

      sendOrderSurvey(props?.order?.data?.id, surveyForm);

      VsToast.show({
        title: $i18n.t("survey.surveyAnsweredMessage.title"),
        variant: "success",
        position: "bottom-right",
        message: $i18n.t("survey.surveyAnsweredMessage.message"),
      });

      surveyAnswered.value = true;
    };

    const fnHandleExplosion = () => {
      setTimeout(() => (explosionHappened.value = true), 1000);
    };

    const orderId = computed(() => props?.order?.data?.id);
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      /* @variables */
      options: shuffle(options.value),
      explosionHappened,
      selectedOption,
      otherInput,
      orderId,
      loading,
      surveyAnswered,
      isEmbedded,
      userReferralStatusIsActive,
      /* @methods */
      surveyOptionSelected,
      fnHandleExplosion,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.konfetti-explosion {
  position: absolute;
  top: 0;
  left: 0;
}

.konfetti-cannon {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.lead-heading {
  .kft-heading__title {
    font-size: 1.5rem;
  }
}

.big-lead {
  font-size: 1.5rem;
  font-weight: 400;
}

.sub-lead {
  font-size: 1rem;
  font-weight: 400;
}

.calendar-button {
  color: #4ab5bd !important;
}

.checkout-success {
  max-width: 600px;
  margin: auto;

  ::v-deep &__referral-info {
    border-top: 1px solid #f4f4f4;
    padding-top: var(--spacer-6);

    --referral-info-user-code-title: $black;
  }
}
</style>
