












































































































































































import {
  KftHeading,
  KftPrice,
  KftSidebar,
  SfImage,
  SfProperty,
} from "@konfetti-ui/vue";
import { cartGetters, useCart, CartKeys } from "@konfetti/composables";

import { computed, ref } from "@nuxtjs/composition-api";

import KftCartProductList from "./organisms/KftCartProductList/KftCartProductList.vue";
import KftChip from "./atoms/KftChip/KftChip.vue";
import { useUiState } from "~/composables";
import { formatPriceAmount } from "~/helpers";

export default {
  name: "CartSidebar",

  components: {
    KftSidebar,
    KftHeading,
    SfProperty,
    KftPrice,
    SfImage,
    KftChip,
    KftCartProductList,
  },

  props: {
    showGiftbox: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { root: { $i18n, $router } }) {
    const { locale } = $i18n;

    const paymentMethods = ref([
      {
        alt: "Mastercard",
        slug: "mastercard",
      },
      {
        alt: "Visa",
        slug: "visa",
      },
      {
        alt: "Paypal",
        slug: "paypal",
      },
    ]);

    /** =====
     * Variables
     * === **/
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();

    const {
      cart,
      loadCart,
      loading: cartLoading,
      toggleGiftbox,
    } = useCart(CartKeys.GENERAL);

    const {
      getItems: fnGetItems,
      getTotals: fnGetTotals,
      getTotalItems: fnGetTotalItems,
      getGiftboxPriceAmount,
      isGiftboxEnabled: fnIsGiftboxEnabled,
      hasGiftcard: fnHasGiftcard,
    } = cartGetters;

    const validationModal = ref(null);

    const items = computed(() => fnGetItems(cart.value));
    const totals = computed(() => fnGetTotals(cart.value));
    const totalItems = computed(() => fnGetTotalItems(cart.value));
    const giftboxPriceAmount = computed(() =>
      getGiftboxPriceAmount(cart.value),
    );
    const isGiftboxEnabled = computed(() => fnIsGiftboxEnabled(cart.value));
    const hasGiftcard = computed(() => fnHasGiftcard(cart.value));

    /** =====
     * This will show information based on the items in the cart
     * all coming from translation files
     * === **/
    const cartItemInfoList = computed(() => {
      const hasGiftcard = cart.value?.items?.find(
        (item) => item.type === "GIFT_CARD",
      );
      const hasGiftbox = cart.value?.isGiftboxEnabled;
      const hasNormalTicket = cart.value?.items?.find(
        (item) => item.type === "EVENT",
      );
      const notEmpty = cart.value?.items.length > 0;
      const hasGiftboxAndGiftcard = hasGiftbox && hasGiftcard;

      if ((hasGiftboxAndGiftcard || hasGiftcard) && hasNormalTicket) {
        return $i18n.te("cart.itemsInfo.various")
          ? $i18n.t("cart.itemsInfo.various")
          : null;
      } else if (hasGiftboxAndGiftcard || hasGiftcard) {
        return $i18n.te("cart.itemsInfo.giftcardAndGiftbox")
          ? $i18n.t("cart.itemsInfo.giftcardAndGiftbox")
          : null;
      } else if (notEmpty) {
        return $i18n.te("cart.itemsInfo.normalTicket")
          ? $i18n.t("cart.itemsInfo.normalTicket")
          : null;
      }

      return null;
    });

    /** =====
     * Methods
     * === **/
    const goToCheckout = () => {
      toggleCartSidebar();
      $router.push({ name: `checkout___${locale}` });
    };

    const toggleGiftboxOption = () => {
      toggleGiftbox();
    };

    return {
      // Attributes
      locale,
      cart,
      cartLoading,
      paymentMethods,

      // Computed
      totals,
      totalItems,
      items,
      giftboxPriceAmount,
      isGiftboxEnabled,
      hasGiftcard,
      validationModal,
      cartItemInfoList,

      // Methods
      loadCart,
      isCartSidebarOpen,
      toggleCartSidebar,
      cartGetters,
      formatPriceAmount,
      goToCheckout,
      toggleGiftboxOption,
      toggleGiftbox,
    };
  },
};
