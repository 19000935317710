import { getSupplierSubdomain } from "~/helpers";

const excludedRoutes = [
  "/embedded/",
  "/ping/",
  "/ping-default/",
  "/ping-optimized/",
];

declare global {
  interface Window {
    onNuxtReady: any;
  }
}

const isRouteExcluded = (path) => {
  for (let i = 0; i < excludedRoutes.length; i++) {
    const current = excludedRoutes[i];
    if (current && path.includes(current)) {
      return true;
    }
  }

  return false;
};

const isEmbeddedCheckout = (route, ssrContext, $config) => {
  const scope = getSupplierSubdomain(
    ssrContext?.nuxt || null,
    $config?.subdomain || null,
  );

  return (
    scope !== null ||
    Boolean(route.query.eventId) ||
    Boolean(route.query.eventDescriptionId) ||
    Boolean(route.query.isEmbeddedGiftcard)
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ app, $gtm, route, ssrContext, $config }): void => {
  if (
    isEmbeddedCheckout(route, ssrContext, $config) ||
    isRouteExcluded(route.path) ||
    typeof window === "undefined" ||
    !window?.localStorage
  ) {
    return;
  }

  window.onNuxtReady(() => {
    if (typeof $gtm.init === "undefined") {
      return;
    }
    $gtm.init();

    // Every time the route changes (fired on initialization too)
    app.router.beforeEach((to, from, next) => {
      app.wait.start("route-changing");

      next();
    });

    const mappingRemainingRoutes = (routeToName) => {
      const simpleRouteName = routeToName?.slice(0, -8);

      const mapping = {
        "e-event": "event",
        "c-categorySlug": "category",
        "p-permalink": "partner",
        giftcard: "giftcard",
        home: "homepage",
        search: "search",
      };

      // eslint-disable-next-line no-prototype-builtins
      if (simpleRouteName && mapping.hasOwnProperty(simpleRouteName)) {
        return mapping[simpleRouteName];
      } else {
        return "other";
      }
    };

    const getContentGroup = (routeTo) => {
      if (routeTo?.meta?.contentGroup) {
        return routeTo.meta.contentGroup;
      } else if (routeTo?.meta[0]?.contentGroup) {
        // for some reason the meta object changes format between ssr and csr
        return routeTo.meta[0].contentGroup;
      } else {
        return mappingRemainingRoutes(routeTo.name);
      }
    };

    const censorSensitiveDataFromUrl = (url) => {
      const pageUrlURL = new URL(url);

      if (pageUrlURL.searchParams.get("code")) {
        pageUrlURL.searchParams.set("code", "censored_query_value");
      }

      return pageUrlURL;
    };

    const sendNuxtRoute = (to) => {
      setTimeout(() => {
        const pageUrl = censorSensitiveDataFromUrl(
          app.$config.baseUrl + to.fullPath,
        );

        $gtm &&
          $gtm.push({
            routeName: to.name,
            content_group: getContentGroup(to),
            pageType: "PageView",
            pageUrl: pageUrl.href,
            pagePath: pageUrl.pathname,
            pageTitle: document?.title || "",
            event: "nuxtRoute",
          });
      }, 1000);
    };

    // sends page view event when app loads
    sendNuxtRoute(route);

    app.router.afterEach((to, from) => {
      /* Remove duplicated $gtm push events in search page (happens because of the redirect) */
      if (from.name === to.name && from.name?.includes("search")) {
        app.wait.end("route-changing");

        return;
      }
      sendNuxtRoute(to);

      setTimeout(() => app.wait.end("route-changing"), 800);
    });
  });
};
