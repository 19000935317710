import { UseKftContext } from "@konfetti-core/core";
import VsToast from "@vuesimple/vs-toast";
import {
  Error400,
  Error402,
  Error422,
  ValidationError,
} from "@konfetti/composables/src";
import { ErrorHandlerComposable } from "../types";
import {
  ErrorStoreError,
  ErrorStoreErrorType,
  KftError,
  StripeValidationError,
} from "../../useErrorStore";
import { useCartValidationErrorHandler } from "../useCartValidationErrorHandler";

const StripeValidationErrorCode = "validation_error";

const useOrderErrorHandler: ErrorHandlerComposable = () => {
  const { i18n } = UseKftContext();
  const { handle: handleCartValidationError } = useCartValidationErrorHandler();

  const handle400Error = (error: Error400) => {
    if (error.reason === "cartItemsValidation") {
      handleCartValidationError({
        error,
        type: ErrorStoreErrorType.CartValidationError,
      });
    }
  };

  const handleGeneralError = (error: KftError) => {
    VsToast?.show({
      title: i18n.t("general.error"),
      position: "top-center",
      variant: "error",
      message: error?.message || i18n.t("general.error"),
    });
  };

  const handle402Error = (error: Error402) => {
    handleGeneralError(error);
  };

  const handle422Error = (error: Error422) => {
    const errorMessages = Object.entries(error.errors)
      .map(([_, messages]) => messages.join("; "))
      .join("; ");

    VsToast?.show({
      title: i18n.t("general.error"),
      position: "bottom-right",
      variant: "error",
      message: errorMessages,
    });
  };

  const isValidationError = (error: KftError): error is ValidationError => {
    const errors = (error as ValidationError).errors;
    return errors !== undefined && errors.length > 0;
  };

  const handle = ({ error }: ErrorStoreError) => {
    if (!error) {
      return;
    }

    if (!isValidationError(error)) {
      handleGeneralError(error);
      return;
    }

    error = (error?.[0] || error) as ValidationError;

    if (!error.code) {
      return;
    }

    switch (error.code) {
      case 400:
        handle400Error(error as Error400);
        break;
      case 402:
        handle402Error(error as Error402);
        break;
      case 422:
        handle422Error(error as Error422);
        break;
      default:
        handleGeneralError(error);
        break;
    }
  };

  return {
    handle,
  };
};

export { useOrderErrorHandler };
