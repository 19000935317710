










































































































































































import { computed, onMounted, ref, watch } from "@vue/composition-api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/locale/en";
import { KftHeading } from "@konfetti-ui/vue";
import {
  useCart,
  useCheckout,
  CartKeys,
  cartGetters,
} from "@konfetti/composables";
import KftMultiselect from "../../organisms/KftMultiselect/KftMultiselect.vue";
import { useFormValidation } from "~/composables/useFormValidation";
import { useEmbeddedCheckout } from "~/composables/useEmbeddedCheckout";
import { loadingWrapper } from "~/helpers";
import { fnGetISO2Locale } from "~/helpers/localeHelpers";
import { fnConvertLocaleIntoBCP47, formatMoney } from "~/helpers";

export default {
  name: "KftAddressForm",

  components: {
    DatePicker,
    KftHeading,
    Multiselect: KftMultiselect,
  },

  props: {
    shippingDetails: {
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      locality: "",
      country: "",
      // eslint-disable-next-line camelcase
      additional_information: "",
    },
    dateOfBirth: "",
    showDateOfBirth: false,
    controlVariables: {},
  },

  setup(props, { emit, root: { $i18n } }) {
    const form = ref({
      first_name: "",
      // eslint-disable-next-line camelcase
      last_name: "",
      street: "",
      // eslint-disable-next-line camelcase
      street_number: "",
      zipcode: "",
      locality: "",
      country: "",
      // eslint-disable-next-line camelcase
      additional_information: "",
      // eslint-disable-next-line camelcase
      date_of_birth: "",
    });
    const rules = ref({
      first_name: "required",
      last_name: "required",
      // eslint-disable-next-line camelcase
      street: "required",
      locality: "required",
      zipcode: "required",
      country: "required",
      // eslint-disable-next-line camelcase
      street_number: "required",
    });

    const localDateOfBirth = ref(null);

    const { fnLoadShippingCosts, loadShippingCostsForEvents, shippingCosts } =
      useCheckout();
    const { isEmbedded } = useEmbeddedCheckout();

    const { cart, loadCart, fnSetShippingCost } = useCart(
      isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL,
    );

    const countriesValueArr = computed(
      () =>
        shippingCosts.value?.data?.map((x) => x.country.data.codeIsoAlpha2) ||
        [],
    );

    const computedCountryNamesMap = computed(() => {
      const map = {};
      shippingCosts.value?.data.forEach((x) => {
        map[x.country.data.codeIsoAlpha2] = x.country.data.name;
      });

      return map;
    });

    const selectedCountryData = computed(() =>
      shippingCosts.value?.data?.find(
        (x) => x.country.data.codeIsoAlpha2 === form.value.country,
      ),
    );

    const controlVariables = ref(props.controlVariables);
    const { errors, hasError, validate, invalidKeys } = useFormValidation(
      form,
      rules,
      controlVariables,
    );

    watch(props.showDateOfBirth, () => {
      if (cartGetters.getNeedsMajority(cart) || props.showDateOfBirth) {
        rules.value = { ...rules, date_of_birth: "required|beforeNYears:18" };
      }
    });

    const handleDateChange = (value) => {
      localDateOfBirth.value = value;
      form.value.date_of_birth = value;
      fnValidateForm("date_of_birth", false);
    };

    /**
     * @name fnValidateForm
     * @description used when an address is inputted, if the form is not valid, it blocks the order
     * @param key the key to be validated, null for all
     * @param showErrors If the view should display the validation errors
     * */
    const fnValidateForm = async (key, showErrors = true) => {
      await validate(key);

      if (showErrors && hasError()) {
        emit("invalid");
      }

      emit("valid", form.value);
    };

    const mergeShippingCosts = (giftcardShippingCost, eventShippingCost) => {
      // If there's no configured event shipping costs, default is giftcard shipping cost
      if (!eventShippingCost?.length) return giftcardShippingCost;
      let mergedCost = { ...giftcardShippingCost };
      // compute quantity of items in cart
      const quantities = cart.value?.items
        .filter((item) => item?.hasShippingCosts)
        .map((item) => item?.quantity || 1);
      // compute quantity of giftcards
      const giftcardQuantity = cart.value?.items
        .filter((item) => item?.type === "GIFT_CARD")
        .reduce((acc, item) => (acc += item?.quantity ?? 1), 0);
      // get giftbox shipping amount
      const giftcardShippingAmount = giftcardShippingCost?.value?.amount
        ? parseInt(giftcardShippingCost?.value.amount, 10) * giftcardQuantity
        : 0;
      // Add eventShippings costs (amount and formatted to total shipping costs)
      const totalShippingCostsAmount = eventShippingCost.reduce(
        (acc, price, index) =>
          (acc += parseInt(price?.value?.amount, 10) * quantities[index]),
        giftcardShippingAmount,
      );
      mergedCost.value.amount = totalShippingCostsAmount.toString();
      mergedCost.value.formatted = formatMoney(
        totalShippingCostsAmount / 100,
        fnConvertLocaleIntoBCP47($i18n.locale),
        "EUR",
        2,
      );

      return mergedCost;
    };

    const fnOnChangeCountry = async () => {
      await fnLoadShippingCosts();
      const itemIdsForShipping = cart.value?.items
        .filter((item) => item?.hasShippingCosts)
        .map((item) => item?.eventDescriptionId);

      const eventShippingsCosts = await loadShippingCostsForEvents(
        itemIdsForShipping,
        selectedCountryData.value?.country?.data?.codeIsoAlpha2,
      );
      const mergedShippingCosts = mergeShippingCosts(
        selectedCountryData.value,
        eventShippingsCosts,
      );
      fnSetShippingCost(mergedShippingCosts);
    };

    onMounted(() => {
      loadingWrapper(async () => {
        await fnLoadShippingCosts();
        if (selectedCountryData.value) {
          await fnOnChangeCountry();
        }
      });
      form.value = props.shippingDetails;
      localDateOfBirth.value = props.dateOfBirth;
      form.value.date_of_birth = props.dateOfBirth;
    });

    return {
      form,
      cart,
      errors,
      hasError,
      validate,
      invalidKeys,
      countriesValueArr,
      shippingCosts,
      localDateOfBirth,
      computedCountryNamesMap,

      fnValidateForm,
      fnOnChangeCountry,
      fnGetISO2Locale,
      cartGetters,
      handleDateChange,
    };
  },
};
