/**
 * @todo
 *
 * 1. error messages
 * 2. clean field when typing
 * */
import { UseKftContext } from "@konfetti-core/core";

import type { Ref } from "@vue/composition-api";
import { ref } from "@vue/composition-api";

/* @todo when it grows, we can make the imports dynamic */
import email from "./validators/email";
import max from "./validators/max";
import min from "./validators/min";
import required from "./validators/required";
import sameValueAs from "./validators/sameValueAs";
import shouldBeTrue from "./validators/shouldBeTrue";
import regex from "./validators/regex";
import beforeNYears from "./validators/beforeNYears";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormValidation = (
  form: Ref<any>,
  rules: Ref<any>,
  controlVariables: Ref<any> | null = undefined,
) => {
  const { i18n } = UseKftContext();

  /* @todo when it grows, we can make the imports dynamic */
  const validators = ref({
    email,
    max,
    min,
    required,
    sameValueAs,
    regex,
    shouldBeTrue,
    beforeNYears,
  });

  const invalidKeys = ref([]);
  const errors = ref({});
  const parsedRules = ref({});
  const keys = ref([]);

  const hasError = (): boolean => {
    return invalidKeys.value.length > 0;
  };

  /**
   *
   * { rules: 'required|email|min:6|max:10', message (optional): 'Please enter a valid email address' }
   * */
  const getFormKeys = () => {
    return Object.keys(form.value);
  };

  /**
   * Clear an specific field if parameter is given
   * Clear all the errors otherwise
   * */
  const clearErrors = (key: any = false) => {
    if (!key || key === "") {
      errors.value = {};
      invalidKeys.value = [];
      return;
    }

    if (typeof errors.value[key] === "undefined") {
      return;
    }

    const index = invalidKeys.value.indexOf(key);
    invalidKeys.value.splice(index, 1);
    delete errors.value[key];
  };

  /**
   * @desc This function parses laravel-like string into 'label:- value' objects
   *
   * rulesObject contains
   *
   * */
  const parseKeyRules = (ruleString: string) => {
    const parsedKeyRules = [];
    const ruleNames = ruleString.split("|");
    for (let i = 0; i < ruleNames.length; i++) {
      const rule = ruleNames[i] as string;

      /* label, value */
      if (!rule.includes(":")) {
        parsedKeyRules.push({
          label: rule,
          value: null,
          message: i18n.t(`validation.${rule}`),
        });
        continue;
      }

      const splittedRule = rule.split(":");
      const complexRule = [splittedRule[0], splittedRule.splice(1).join(":")];

      /* Because of this, it is required that the validation variables are named the same name as the validation rule
       * In the future we could make an additional ':' to expose the key name
       * but the ':' could be used for better reasons than that, so waiting for now...
       *  */
      const translationParamsObject = {};
      translationParamsObject[complexRule[0]] = complexRule[1];

      parsedKeyRules.push({
        label: complexRule[0],
        value: complexRule[1],
        message: i18n.t(
          `validation.${complexRule[0]}`,
          translationParamsObject,
        ),
      });
    }

    return parsedKeyRules;
  };

  const mountRulesObject = () => {
    const keys = Object.keys(rules.value);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      parsedRules.value[key] = parseKeyRules(rules.value[key]);
    }
  };

  const validateKey = (key) => {
    for (let i = 0; i < parsedRules.value[key].length; i++) {
      const rule = parsedRules.value[key][i];

      /**
       * If the control variable is not checked, skip validation
       * */
      if (
        rule.label === "checked" &&
        controlVariables?.value?.[rule.value] !== true
      ) {
        return null;
      }

      /**
       * If the function does not exist, skip validation
       * */
      if (!validators?.value?.[rule.label]) {
        continue;
      }

      /**
       * If the function exists and has more than one argument (which means it needs an additional value)
       * */
      if (validators.value[rule.label].length > 1) {
        const result = validators.value[rule.label](form, key, rule.value);
        if (!result) {
          invalidKeys.value.push(key);
          return (errors.value[key] = rule.message);
        }

        continue;
      }

      /**
       * Basic validation with only one parameter
       * */
      const result = validators.value[rule.label](form.value[key]);
      if (!result) {
        invalidKeys.value.push(key);
        return (errors.value[key] = rule.message);
      }
    }

    const index = invalidKeys.value.indexOf(key);

    if (index >= 0) {
      invalidKeys.value.splice(index, 1);
    }

    delete errors.value[key];
  };

  const validate = async (
    keyToValidate: string | undefined | null = undefined,
  ) => {
    if (keyToValidate && parsedRules.value[keyToValidate]) {
      await clearErrors(keyToValidate);
      validateKey(keyToValidate);

      return;
    }

    await clearErrors();
    for (let i = 0; i < keys.value.length; i++) {
      const key = keys.value[i];
      if (typeof parsedRules.value[key] === "undefined") {
        continue;
      }

      validateKey(key);
    }
  };

  const init = () => {
    keys.value = getFormKeys();

    mountRulesObject();
    clearErrors();
  };

  init();

  return {
    /* variables */
    errors,
    invalidKeys,

    /* methods */
    hasError,
    validate,
    clearErrors,
    getFormKeys,
  };
};
