<template>
  <div>
    <StripeElements
      v-if="stripeLoaded"
      id="sepa-element"
      ref="stripeElements"
      v-slot="{ elements }"
      :elements-options="stripeElementsOptions"
      :instance="stripeInstance"
      :stripe-key="stripeClientKey"
    >
      <StripeElement
        ref="IBANElement"
        :elements="elements"
        :options="{ supportedCountries }"
        type="iban"
        data-test-id="sepa-element"
      />
    </StripeElements>
  </div>
</template>

<script type="ts">
/* eslint-disable camelcase */

import { onBeforeMount, ref } from "@vue/composition-api";
import { sharedRef } from "@konfetti-core/core";
import { StripeElement, StripeElements } from "../../molecules/KftStripe";
import {
  useStripeInstance,
  useSEPA,
} from "~/composables";
import { fnGetISO2Locale } from "~/helpers/localeHelpers";

export default {
  name: "StripeSepaElement",
  components: {
    StripeElements,
    StripeElement,
  },
  props: {
    hasAccessToWindowObject: {
      type: Boolean,
      default: true,
    },
    customToken: {
      type: String,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
    useBalance: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["success"],

  setup(props, {
    emit,
    root: {
      $config,
      $i18n,
    },
  }) {
    const IBANElement = sharedRef(null, "stripe-sepa-element");
    const paymentToken = sharedRef(null, "kft-stripe-card-checkout");
    const stripeElements = sharedRef(null, "kft-stripe-card-checkout");

    const {
      stripeLoaded,
      fnLoadStripe,
      stripeInstance,
    } = useStripeInstance("general-instance");

    const {
      submitPayment,
      order,
    } = useSEPA("general-instance", true);

    const stripeClientKey = ref($config.stripeClientKey);

    const submitSEPAPayment = async (orderParams, email) => {
      const submitPaymentResult = await submitPayment(orderParams, IBANElement?.value.stripeElement, email);
      if (submitPaymentResult) {
        emit("success", order.value);
      } else {
        emit("error", order.value);
      }
    };

    /* @see https://stripe.com/docs/js/elements_object/create#stripe_elements-options */
    const stripeElementsOptions = ref({
      locale: "de",
      fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap" }],
    });

    const supportedCountries = ["SEPA"];

    /**
     * @name fnExecutePaymentWithIntent
     * @description
     * Function will be called when the 3DS form is confirmed
     * It is called via callback from 3DS
     *
     * @param params The mounted order params
     * @param intentToken Intent token, received when starting the 3DS process
     * @param token The payment method token
     * */
    const fnExecutePaymentWithIntent = async (params, intentToken, token) => {
      if (token) {
        paymentToken.value = token;
      }

    };

    /**
     * HOOKS
     * */
    onBeforeMount(async () => {
      stripeElementsOptions.value.locale = fnGetISO2Locale($i18n.locale);

      await fnLoadStripe(stripeClientKey.value);
    });

    return {
      stripeClientKey,
      stripeElementsOptions,
      stripeLoaded,
      stripeElements,
      submitSEPAPayment,
      IBANElement,
      fnExecutePaymentWithIntent,
      stripeInstance,
      supportedCountries,
    };
  },
};
</script>

<style lang="scss">
#card-element {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}
</style>
