import { UseKftContext } from "@konfetti-core/core";
import { JwtService } from "@konfetti/api";
import * as api from "@konfetti/api";
import axios from "axios";

export const useApiHandler = (hasAccessToWindowObject = true): any => {
  let context = null;
  let jwtToken = null;

  try {
    context = UseKftContext();
  } catch (e) {
    context = null;
  }

  const localeArr = context.i18n.locale.split("-");
  const localeBackend = localeArr[0] + "_" + localeArr[1].toUpperCase();

  if (hasAccessToWindowObject && typeof window !== "undefined") {
    jwtToken = JwtService.getToken();
  }

  const setJwtToken = (token) => {
    jwtToken = token;
  };

  const makeRequest = async (method, ...args) => {
    const payload = {
      token: jwtToken,
      locale: context.i18n.locale,
    };

    if (process.env.NODE_ENV === "test") {
      const { type, result } = await context.$konfetti.api[method](
        payload,
        ...args,
      );

      return type === "resolve"
        ? Promise.resolve(result)
        : Promise.reject(result);
    }

    const axiosInstance = axios.create({
      headers: {
        "Accept-Language": localeBackend,
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        if (["post", "put", "patch"].includes(config.method.toLowerCase())) {
          const sourceVersionSha = context.$config.sourceVersionSha;
          if (sourceVersionSha) {
            config.data.fv = `store/${sourceVersionSha}`;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return api[method](
      {
        client: axiosInstance,
        config: {
          api: {
            url: context.$config.apiUrl,
          },
        },
      },
      ...args,
    );
  };

  const makeStrapiRequest = async (method, ...args) => {
    const token = context.$config.strapiApiKey;

    return api[method](
      {
        client: axios.create({
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        config: {
          strapiApi: {
            url: context.$config.strapiApiUrl,
          },
        },
      },
      ...args,
    );
  };

  const cacheSetKey = async (key, value) => {
    const token = context.$config.strapiApiKey;

    return await context.$konfetti.api.setKey(
      { token, locale: localeBackend },
      { key, value },
    );
  };

  const cacheGetKey = async (key) => {
    const token = context.$config.strapiApiKey;

    return await context.$konfetti.api.getKey(
      { token, locale: localeBackend },
      key,
    );
  };

  return {
    cacheGetKey,
    cacheSetKey,
    makeRequest,
    makeStrapiRequest,
    setJwtToken,
  };
};
