import { Context } from "@konfetti-core/core";

export async function getShippingCosts(context: Context): Promise<any> {
  const url = new URL(
    "v1/store/shipping/costs?include=country",
    context.config.api.url,
  );
  // Use axios to send a GET request
  const { data } = await context.client.get(url);
  // Return data from the API
  return data;
}

export async function getShippingCostForEvent(
  context: Context,
  id: string,
  countryCode: string,
): Promise<any> {
  const url = new URL(
    `v1/store/events/${id}/shipping-costs?country=${countryCode}`,
    context.config.api.url,
  );

  const { data } = await context.client.get(url);
  return data;
}
