import { cartGetters, useCart, CartKeys } from "@konfetti/composables";
import { CartValidationError, Error400 } from "@konfetti/composables/src";
import { UseKftContext } from "@konfetti-core/core";
import { useEmbeddedCheckout } from "../../useEmbeddedCheckout";
import { ErrorStoreError } from "../../useErrorStore";
import { ErrorHandlerComposable } from "../types";

const useCartValidationErrorHandler: ErrorHandlerComposable = () => {
  const { isEmbedded } = useEmbeddedCheckout();
  const {
    $showKftValidationModal,
    app: { router },
    localePath,
  } = UseKftContext();
  const key = isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL;

  const { cart, updateItemQuantity, removeItem, cartIsEmpty } = useCart(key);

  const fnParseCartErrors = (
    cartItemsValidationErrorResponse: Error400 | Error400[],
  ) => {
    return (
      cartItemsValidationErrorResponse?.[0]?.errors ||
      (cartItemsValidationErrorResponse as Error400)?.errors
    );
  };

  const handleCartItemWasUpdated = (error: CartValidationError) => {
    if (!error?.item) return;
    const price = error.item.price;
    const originalPrice = error.item.original_price;
    const id = error.item.id;

    const outdatedItem = cart.value.items.find((item) => item.id === id);

    if (!outdatedItem) return;
    outdatedItem.price = price;
    outdatedItem.originalPrice = originalPrice;
    outdatedItem.wasUpdated = true;
  };

  const handleNotPublicError = (error: CartValidationError) => {
    const item = cart.value.items.find((x) => error?.date.id === x.id);
    if (!item) return;
    removeItem(item);
  };

  const handleNotEnoughError = (error: CartValidationError) => {
    updateItemQuantity(error?.date, error?.date?.availability);
  };

  const removeItemByError = (error: CartValidationError) => {
    const obj = cart.value.items.find(
      (x) => error?.date?.id || error?.event?.id === cartGetters.getItemId(x),
    );

    if (obj) {
      removeItem(obj);
    }
  };

  const handlers = {
    notAvailable: removeItemByError,
    notFound: removeItemByError,
    soldOut: removeItemByError,
    minAdvance: removeItemByError,
    maxAdvance: removeItemByError,
    notEnough: handleNotEnoughError,
    notPublic: handleNotPublicError,
    cartItemWasUpdated: handleCartItemWasUpdated,
  };

  const handleInvalidItems = (errors: CartValidationError[]): void | [] => {
    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      handlers[error.reason]?.(error);
    }
  };

  const handle = ({ error }: ErrorStoreError<Error400>) => {
    const parsedCartItemErrors = fnParseCartErrors(error);

    if (!parsedCartItemErrors || parsedCartItemErrors.length === 0) {
      return;
    }

    handleInvalidItems(parsedCartItemErrors);
    $showKftValidationModal(parsedCartItemErrors);

    if (cartIsEmpty.value) {
      router.push(localePath("/"));
    }
  };

  return {
    handle,
  };
};

export { useCartValidationErrorHandler };
