

























import { nextTick } from "@nuxtjs/composition-api";
import { useGtm } from "~/composables";

/**
 * @filetype Component
 * @description This file contains the social login data, responsible for sign-in/registration with social media
 * */

export default {
  name: "KftSocialLogin",

  setup(props, { root: { $config, $wait, $router } }) {
    const { sendSocialLoginAttempt } = useGtm();
    const handleClickSocialLogin = async (socialMediaSlug) => {
      sendSocialLoginAttempt(socialMediaSlug);

      await setTimeout(async () => {
        window.location.href = `${$config.apiUrl}v1/auth/${socialMediaSlug}/redirect`;

        await nextTick();

        $wait.end("validating-user");
      }, 200);
    };
    return {
      /* Variables */
      /* Methods */
      handleClickSocialLogin,
    };
  },
};
