export default {
  validation: {
    min: "Bitte gebe mindestens {min} Zeichen ein.",
    max: "Es besteht Platz für maximal {max} Personen",
    required: "Dieses Feld wird benötigt",
    email: "Bitte gebe eine gültige E-Mail ein",
    sameValueAs: "Dieses Feld sollte übereinstimmen mit {sameValueAs}",
    shouldBeTrue: "Dieses Feld muss angekreuzt werden",
    checked: "",
    beforeNYears:
      "Du musst mindestens 18 Jahre alt sein um die Bestellung abzuschließen",
  },
};
