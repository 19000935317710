/* eslint-disable camelcase, @typescript-eslint/explicit-module-boundary-types */

/**
 * @description This function prepares the object to create an order
 * in the API
 *
 * Available Gateways and required variables:
 *
 * 'STRIPE': cartToken (string) - paymentIntentId
 * 'KONFETTI': empty (used to buy tickets with credits or vouchers
 * */

const fnCreateOrderObject = (
  gateway: string,
  paymentData: any,
  items: Array<any>,
  shippingAddress: any = null,
  invoiceAddress: any = null,
  coupon: string = null,
  external = false,
  paymentMethod: string = null,
  useBalance = true,
) => {
  return {
    payment_gateway: gateway,
    external: external,

    /* Stripe Gateway */
    ...(gateway === "STRIPE" && {
      stripe: {
        payment_method: paymentMethod,
        credit_card_id: paymentData?.cardToken || null,
        store_credit_card: true,
        ...(paymentData?.paymentIntentId && {
          payment_intent_id: paymentData?.paymentIntentId || null,
        }),
      },
    }),

    /* Items */
    items,

    /* Address, if exists */
    ...(shippingAddress !== null && {
      ship_address: (({ date_of_birth, ...rest }) => rest)(shippingAddress),
    }),
    ...(shippingAddress?.date_of_birth && {
      birth: shippingAddress?.date_of_birth,
    }),
    /* Invoice Address, if exists */
    ...(invoiceAddress !== null && {
      invoice_address: invoiceAddress,
    }),

    /* Coupon, if exists */
    ...(coupon !== null && {
      coupon_id: coupon,
    }),

    use_balance: useBalance,
  };
};

export { fnCreateOrderObject };
