import { Logger, sharedRef } from "@konfetti-core/core";
import { computed } from "@vue/composition-api";
import { useCart, useApiHandler } from "@konfetti/composables/src";
import type { Error400 } from "@konfetti/composables/src/types";

export enum CartKeys {
  GENERAL = "general-instance",
  EMBEDDED = "embedded-instance",
}

export const useCartValidation = (id: string): any => {
  /* Cart */
  const { cart, loadCart } = useCart(id);

  const cartItemsValidationError = sharedRef<Error400>(
    null,
    `useCart-cartValidationError-${id}`,
  );

  /** Wrapper for API requests */
  const { makeRequest } = useApiHandler();

  const fnCreateCartValidationItemsObject = async () => {
    const response = [];

    // We need to load cart to make sure we are validating the latest
    // instance of the cart
    await loadCart();
    if (cart.value.items.length === 0) {
      return response;
    }

    for (let i = 0; i < cart.value.items.length; i++) {
      const item = cart.value.items[i];
      response.push({
        type: item.type,
        ...(item.type !== "GIFT_CARD" && { product: item.id }),
        quantity: item.quantity,
        private: item.private,
        value: item?.price?.amount,
        price: (parseInt(item?.price?.amount) / 100).toFixed(2),
        ...(item.type === "GIFT_CARD" && { event: item.eventDescriptionId }),
      });
    }

    return response;
  };

  const fnResetCartItemsValidationError = () => {
    cartItemsValidationError.value = null;
  };

  const fnValidateCartItems = async () => {
    fnResetCartItemsValidationError();
    const items = await fnCreateCartValidationItemsObject();
    Logger.debug(`useCart/${id}/validateCartItems`, items);

    let valid = true;

    if (items.length === 0) {
      return valid;
    }

    try {
      const resp = await makeRequest("validateCartItems", { items });
      Logger.debug(`useCart/${id}/validateCartItems`, resp);
    } catch (err) {
      if (
        err?.response?.data?.errors?.length === 1 &&
        err?.response?.data?.errors?.[0]?.reason === "RequiredAgeOfMajority"
      ) {
        return "handleRequestDateOfBirth";
      }
      cartItemsValidationError.value = err?.response?.data || err;
      Logger.error(`useCart/${id}/validateCartItems`, err);

      valid = false;
    }

    return valid;
  };

  return {
    // Variables
    cartItemsValidationError: computed(() => cartItemsValidationError.value),

    // Methods
    fnValidateCartItems,
    fnResetCartItemsValidationError,
  };
};
