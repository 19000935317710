import { middlewareConfig } from "./middlewareConfig";

export const publicRuntimeConfig = {
  appKey: "kft2Connector" + Date.now(),
  subdomain: null,
  paypalClientKey:
    process.env.PAYPAL_CLIENT_KEY ||
    "AXugmByq1A6COukdYtZs4wK4NOvbV7x2puLgL5Ip5Io4KMI9xlmjl6gsqnxU9d_cj8-ORO3jFxbCFkEX",
  stripeClientKey:
    process.env.STRIPE_CLIENT_KEY ||
    "pk_live_51IoU4NLc3V4bsftki35jo893GW0gPbOiDOUenmwiXEtkgKNprqkWfRaNaRS4lPhVHfuBLeDiIw3mnldDaumOtv3b001p1vNFvJ",
  baseUrl: process.env.BASE_URL || "https://front-hml.gokonfetti.com",
  paypalIntegration: process.env.PAYPAL_INTEGRATION || undefined,
  apiUrl: middlewareConfig.integrations.konfetti.configuration.api.url,
  strapiApiUrl:
    middlewareConfig.integrations.konfetti.configuration.strapiApi.url,
  algolia: middlewareConfig.integrations.konfetti.configuration.algolia,
  nodeEnv: process.env.APP_ENV || process.env.NODE_ENV || "production",
  mediaUrl: process.env.KFT_MEDIA_BASE_URL || "https://media.gokonfetti.com",
  sourceVersionSha: process.env.SOURCE_VERSION_SHA,
  strapiApiKey:
    process.env.STRAPI_API_KEY ||
    "533baa84b3f768b7855c92008806878918a6992a2eebe0ae596e5b6cbe6372a8b04511de47bc285b6c02e52d3dc71994735f7fc840196c028fd49bf2088b7373058e846b19c5bab0e89ee6e77e2b553bbee31f61b6c852b2bb213514051f75163842f1f458b59456d074f9afb418c5574019c3ef2a7eb58918ffbabd29ecb87c",
};
