<template>
  <div>
    <StripeElements
      v-if="stripeLoaded"
      id="card-element"
      ref="stripeElements"
      v-slot="{ elements }"
      :elements-options="stripeElementsOptions"
      :instance="stripeInstance"
      :stripe-key="stripeClientKey"
    >
      <StripeElement
        ref="card"
        :elements="elements"
        :options="{ hidePostalCode: true }"
        data-test-id="card-element"
      />
    </StripeElements>
    <KftModal
      id="stripe3dModal"
      ref="stripe3dModal"
      :card-container="false"
      @hidden="$emit('3DS-authentication-modal-hidden')"
    >
      <div
        :style="`background: white; width:${iframeDimensions[0]}; height:${iframeDimensions[1]}`"
      >
        <div v-if="showStripe3dIframe" ref="stripe3dIframe"></div>
      </div>
    </KftModal>
  </div>
</template>

<script type="ts">
/* eslint-disable camelcase */

import { onBeforeMount, onMounted, ref } from "@vue/composition-api";
import VsToast from "@vuesimple/vs-toast";
import { sharedRef } from "@konfetti-core/core";
import { KftModal } from "@konfetti-ui/vue";
import { StripeElement, StripeElements } from "../../molecules/KftStripe";
import {
  useOrderValidation,
  useStripeCreditCard,
  useStripeInstance,
  useErrorStore,
  ErrorStoreErrorType,
} from "~/composables";
import { fnGetISO2Locale } from "~/helpers/localeHelpers";

/**
 * @name KftStripeCardCheckout
 * @description Used to do all stripe card operations
 *
 * @note Some functions are not in the composable because they depend directly on the stripeElement `card`
 *
 * @usage /checkout/payment, /embedded/checkout/payment
 * */
export default {
  name: "KftStripeCardCheckout",
  components: {
    KftModal,
    StripeElements,
    StripeElement,
  },
  props: {
    hasAccessToWindowObject: {
      type: Boolean,
      default: true,
    },
    customToken: {
      type: String,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
    useBalance: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["success"],

  setup(props, {
    emit,
    root: {
      $config,
      $wait,
      $i18n,
    },
  }) {
    const paymentToken = sharedRef(null, "kft-stripe-card-checkout");
    const stripeElements = sharedRef(null, "kft-stripe-card-checkout");

    const {
      card,
      error,
      code,
      order,
      fnSubmitOrder,
    } = useStripeCreditCard("payment-instance", props.hasAccessToWindowObject, props.customToken);

    const {
      stripeLoaded,
      fnLoadStripe,
      stripeInstance,
    } = useStripeInstance("general-instance");

    const { handleError } = useErrorStore();

    /**
     *  Stripe
     *  */
    const stripeClientKey = ref($config.stripeClientKey);

    /* @see https://stripe.com/docs/js/elements_object/create#stripe_elements-options */
    const stripeElementsOptions = ref({
      locale: "de",
      fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap" }],
      // clientSecret: null,
      //  appearance
      //  loader
    });

    const stripe3dIframe = ref(null);
    const showStripe3dIframe = ref(true);
    const stripe3dModal = ref(null);
    const iframeDimensions = [390, 400];

    /**
     * @name render3DAuthIframe
     * @param url The callback URL
     * */
    const render3DAuthIframe = (url) => {
      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.width = iframeDimensions[0];
      iframe.height = iframeDimensions[1];

      if (!stripe3dIframe.value.firstChild) {
        stripe3dIframe.value.appendChild(iframe);
      } else {
        stripe3dIframe.value.firstChild.replaceWith(iframe);
      }
    };

    /**
     * @name submit3DAuth
     * @description
     * Function will submit the confirmation
     *
     * @param stripeClientSecret
     * */
    const submit3DAuth = (stripeClientSecret) => {
      emit("3DS-authentication-started");
      const element = card.value?.stripeElement;
      if (!stripeInstance.value || !element) {
        return;
      }

      stripeInstance.value.confirmCardPayment(stripeClientSecret,
        {
          payment_method: { card: element },
          return_url: `${$config.baseUrl}/de-de/stripe-3d-auth-complete/`,
        },
        // Disable the default next action handling.
        { handleActions: false },
      ).then((result) => {

        if (result.error && result.error.message) {

          VsToast.show({
            title: $i18n.t("general.error"),
            variant: "error",
            position: "bottom-right",
            message: result.error.message,
          });

          emit("error");
          handleError({type: ErrorStoreErrorType.Stripe3DAuthError, error: result.error});

        } else {
          const { paymentIntent } = result;
          stripe3dModal.value.show();
          render3DAuthIframe(paymentIntent.next_action.redirect_to_url.url);
        }
      }).catch((error) => {
        handleError({type: ErrorStoreErrorType.Stripe3DAuthError, error});

        emit("error", error);
      });
    };

    /**
     * @name fnExecutePaymentWithIntent
     * @description
     * Function will be called when the 3DS form is confirmed
     * It is called via callback from 3DS
     *
     * @param params The mounted order params
     * @param intentToken Intent token, received when starting the 3DS process
     * @param token The payment method token
     * */
    const fnExecutePaymentWithIntent = async (params, intentToken, token) => {
      if (token) {
        paymentToken.value = token;
      }

      /* @todo: add custom token in embedded */
      await fnSubmitOrder(params, paymentToken.value, intentToken);

      if (error.value !== null && error.value.length > 0) {
        return;
      }

      emit("success", order.value);
    };

    /**
     * @name fnProcessPayment
     * @description
     * Function process the first stage of a payment,
     * if 3DS is required, then it will call the functions to handle that
     *
     * @param params Mounted object with necessary variables
     * */
    const fnProcessPayment = async (params) => {
      $wait.start("stripe-processing-payment");

      await fnSubmitOrder(params, paymentToken.value, null, props.useBalance);

      /* 3DS Required */
      if (code.value === 406) {
        $wait.start("stripe-start-3ds");

        await submit3DAuth(order.value.clientSecret);

        setTimeout(() => $wait.end("stripe-processing-payment"), 1000);

        return;
      }

      if (error.value === null || error.value.length === 0) {
        emit("success", order.value);
      }

      setTimeout(() => $wait.end("stripe-processing-payment"), 1000);

    };

    /**
     * @name fnSubmitPayment
     * @description
     * Initializes a payment, creating the transaction token
     * Then, validates the request
     * If error => emits the event
     * If success => call fnProcessPayment()
     * @param params Mounted object with necessary variables
     * */
    const fnSubmitPayment = async params => {
      const element = card.value?.stripeElement;
      if (!stripeInstance.value || !element) {
        return;
      }

      $wait.start("stripe-processing-payment");
      await stripeInstance.value
      .createPaymentMethod("card", element)
      .then(async (result) => {

        /* We got an error when creating the payment method */
        if (result.error && result.error.message) {
          emit("error", result.error.message);
          handleError({type: ErrorStoreErrorType.OrderError, error: result.error});

          return;
        }

        if (result?.paymentMethod?.id) {
          paymentToken.value = result.paymentMethod.id;
        }

        /* Process the payment */

        await fnProcessPayment(params);

      }).catch((error) => {
          handleError({type: ErrorStoreErrorType.OrderError, error});

          emit("error", error);
      });

      setTimeout(() => $wait.end("stripe-processing-payment"), 1000);

    };

    /**
     * @name on3DSComplete
     * @description
     * Method called when the iframe redirect page posts the completion message on
     * window object (see onMounted callback)
     */
    const on3DSComplete = () => {

      stripe3dModal.value.hide();
      const element = card.value?.stripeElement;
      if (!stripeInstance.value || !element) {
        return;
      }

      $wait.start("stripe-processing-payment");
      stripeInstance.value
      .retrievePaymentIntent(order.value.clientSecret)
      .then((result) => {
        emit("3DS-authentication-complete");

        if (result.error) {

          VsToast.show({
            title: $i18n.t("general.error"),
            variant: "error",
            position: "bottom-right",
            message: result.error.message,
          });
          emit("3DS-authentication-fail", result.error);
          handleError({type: ErrorStoreErrorType.Stripe3DAuthError, error: result.error});

          return;
        }

        /* result.paymentIntent.status may return:
         /- succeeded
         /- requires_payment_method
         /- requires_action
         */

        if (result.paymentIntent.status === "succeeded") {
          VsToast.show({
            title: $i18n.t(`checkout.stripePayment.stripe3DAuth.alerts.${result.paymentIntent.status}.title`),
            variant: "success",
            position: "bottom-right",
            message: $i18n.t(`checkout.stripePayment.stripe3DAuth.alerts.${result.paymentIntent.status}.message`),
          });

          emit("3DS-authentication-success", { paymentIntent: result.paymentIntent });

          return;
        }

        VsToast.show({
          title: $i18n.t(`checkout.stripePayment.stripe3DAuth.alerts.${result.paymentIntent.status}.title`),
          variant: "error",
          position: "bottom-right",
          message: $i18n.t(`checkout.stripePayment.stripe3DAuth.alerts.${result.paymentIntent.status}.message`),
        });

      }).catch(error => {
        console.error("error", error);
        handleError({type: ErrorStoreErrorType.Stripe3DAuthError, error});

      });

      setTimeout(() => $wait.end("stripe-processing-payment"), 1000);

    };

    /**
     * HOOKS
     * */
    onBeforeMount(async () => {
      stripeElementsOptions.value.locale = fnGetISO2Locale($i18n.locale);

      await fnLoadStripe(stripeClientKey.value);
    });

    onMounted(async () => {

      /* From Stripe docs, when finished, the 3d Auth iframe redirects
       * to our project's page which in turn posts a message
       * to indicate that the authentication is complete */
      // @todo: can we change this?
      await window.addEventListener("message", (ev) => {
        if (ev.data === "3DS-authentication-complete") {
          on3DSComplete();
        }
      }, false);

    });

    return {
      stripeClientKey,
      stripeElementsOptions,
      stripeLoaded,
      card,
      stripeElements,
      stripe3dIframe,
      stripe3dModal,
      showStripe3dIframe,
      iframeDimensions,
      fnSubmitPayment,
      submit3DAuth,
      fnExecutePaymentWithIntent,
      stripeInstance,
    };
  },
};
</script>

<style lang="scss">
#card-element {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}
</style>
