import { onBeforeUnmount, onMounted, ref } from "@nuxtjs/composition-api";
import { CartKeys } from "@konfetti/composables";
import { useEmbeddedCheckout } from "../useEmbeddedCheckout";
import { useCartValidation } from "../useCartValidation";

export const useRegisterCartValidationPolling = (intervalInSeconds = 120) => {
  const cartErrorsPollingIntervalId = ref(null);
  const { isEmbedded } = useEmbeddedCheckout();

  const { fnValidateCartItems } = useCartValidation(
    isEmbedded.value ? CartKeys.EMBEDDED : CartKeys.GENERAL,
  );

  onMounted(() => {
    // Registers polling function for checking cart errors
    cartErrorsPollingIntervalId.value = setInterval(
      async () => await fnValidateCartItems(),
      intervalInSeconds * 1000,
    );
  });

  onBeforeUnmount(() => clearInterval(cartErrorsPollingIntervalId.value));
};
