<template>
  <div class="kft-collected-product">
    <div class="kft-collected-product__main">
      <div class="kft-collected-product__details">
        <slot name="title" v-bind="{ title }">
          <div class="kft-collected-product__title-wraper">
            <component
              :is="componentIs"
              :link="link ? link : ''"
              class="kft-collected-product__title"
            >
              {{ title }}
            </component>
          </div>
        </slot>
        <slot name="price" v-bind="{ specialPrice, regularPrice }">
          <KftPrice
            v-if="regularPrice"
            :regular="regularPrice"
            :special="specialPrice"
          />
        </slot>
      </div>
    </div>
    <div class="kft-collected-product__aside">
      <slot name="image" v-bind="{ image, title }">
        <div class="kft-collected-product__image-container">
          <SfImage
            :alt="title"
            :height="imageHeight"
            :src="image"
            :width="imageWidth"
            class="kft-collected-product__image"
          />
          <template v-if="!hideQty">
            <span v-if="staticQuantity" class="quantity-static">{{ qty }}</span>
            <KftQuantitySelector
              v-else
              :max="availableQty"
              :qty="qty"
              class="kft-collected-product__quantity-selector"
              @input="$emit('input', $event)"
              @cart-action="$emit('cart-action', $event)"
            />
          </template>
        </div>
      </slot>
      <span v-if="gift" class="giftcard-tag"> {{ $t("cart.giftcard") }} </span>
      <span v-if="isPrivate" class="giftcard-tag">
        {{ $t("cart.privateEvent") }}
      </span>
    </div>
    <slot v-if="showRemove" name="remove" v-bind="{ removeHandler }">
      <KftButton class="kft-collected-product__remove" @click="removeHandler">
        <i class="bi bi-trash-fill"></i>
      </KftButton>
    </slot>
  </div>
</template>

<script>
import {
  KftButton,
  KftLink,
  KftPrice,
  KftQuantitySelector,
  SfImage,
  SfProperty,
} from "@konfetti-ui/vue";

export default {
  name: "KftCollectedProduct",
  components: {
    KftButton,
    SfImage,
    KftPrice,
    KftQuantitySelector,
    KftLink,
    SfProperty,
  },
  model: {
    prop: "qty",
  },
  props: {
    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: String,
      default: "",
    },

    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 120,
    },

    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 90,
    },

    /**
     * Product title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Product regular price
     */
    regularPrice: {
      type: [Number, String],
      default: null,
    },

    /**
     * Product special price
     */
    specialPrice: {
      type: [Number, String],
      default: null,
    },

    /**
     * Selected quantity
     */
    qty: {
      type: [Number, String],
      default: 1,
    },

    hideQty: {
      type: Boolean,
      default: false,
    },

    /**
     * Available quantity
     */
    availableQty: {
      type: Number,
      default: 99,
    },

    /**
     * Link to product
     */
    link: {
      type: [String, Object],
      default: "",
    },

    gift: {
      type: Boolean,
    },

    isPrivate: {
      type: Boolean,
    },

    showRemove: {
      type: Boolean,
      default: true,
    },

    staticQuantity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentIs() {
      return this.link ? "KftLink" : "div";
    },
  },
  methods: {
    removeHandler() {
      this.$emit("click:remove");
    },
  },
};
</script>
<style lang="scss">
@import "~/assets/components/organisms/KftCollectedProduct.scss";
</style>
