import { EventAddOn, EventAddOnPrice } from "@konfetti/composables/src";

export const getEventAddOnUnitPriceAmount = (
  addon: EventAddOn,
): number | null => parseInt(addon?.unit_price?.amount as string, 10) ?? null;

export const getEventAddOnId = (addon: EventAddOn): string | undefined =>
  addon?.id;

export const getEventAddOnName = (addon: EventAddOn): string | undefined =>
  addon?.name;

export const getEventAddOnPriceType = (
  addon: EventAddOn,
): EventAddOnPrice | undefined => addon?.price_type;
