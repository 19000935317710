import { Logger } from "@konfetti-core/core";
import { useUserReduced } from "./reduced";
import { JwtService } from "@konfetti/api";
import { computed, nextTick } from "@vue/composition-api";

export const useUserComplete = (id: string): any => {
  const {
    userExistsByEmail,
    user,
    loading,
    error,
    isAuthenticated,
    resetPasswordForm,
    tokens,

    login: reducedLogin,
    register: reducedRegister,
    getUser: reducedGetUser,
    verifyRefreshToken: reducedVerifyRefreshToken,
    checkUserExistsByEmail: reducedCheckUserExistsByEmail,
    resetExists: reducedResetExists,
    resetPassword: reducedResetPassword,
    requestPasswordReset: reducedRequestPasswordReset,
    setTokens,
    setUser,
    setUserExistsByEmail,
    updateCustomer,
    updatePassword,
  } = useUserReduced(`useUser-${id}`, true);

  const logout = () => {
    localStorage.setItem("user", null);

    JwtService.destroyExpiresAt();
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();

    setUser(null);
    setUserExistsByEmail(null);
  };

  const verifyRefreshTokenManually = async (
    accessToken,
    refreshToken,
    expiresAt,
    forceValidation,
  ) => {
    Logger.debug(`useUser/${id}/verifyRefreshToken`);

    await verifyAndSetRefreshToken(
      accessToken,
      refreshToken,
      expiresAt,
      forceValidation,
    );
  };

  const verifyRefreshToken = async (forceValidation = false) => {
    Logger.debug(`useUser/${id}/verifyRefreshToken`);

    const accessToken = JwtService.getToken();
    const refreshToken = JwtService.getRefreshToken();
    const expiresAt = JwtService.getExpiresAt();
    await verifyAndSetRefreshToken(
      accessToken,
      refreshToken,
      expiresAt,
      forceValidation,
    );
  };

  const verifyAndSetRefreshToken = async (
    accessToken,
    refreshToken,
    expiresAt,
    forceValidation,
  ) => {
    Logger.debug(`useUser/${id}/verifyRefreshToken`);

    const storageUser = JSON.parse(localStorage.getItem("user"));
    if (storageUser) {
      setUser(storageUser);
      await nextTick();
    }

    setTokens(accessToken, refreshToken, expiresAt);
    if (!refreshToken || !accessToken) {
      logout();

      return;
    }

    if (
      parseInt(expiresAt) > Math.floor(Date.now() / 1000) &&
      !forceValidation
    ) {
      return;
    }

    await reducedVerifyRefreshToken(accessToken, refreshToken);
    if (!tokens.value || error.value !== null) {
      logout();
      return;
    }

    JwtService.saveToken(tokens.value.access_token);
    JwtService.saveRefreshToken(tokens.value.refresh_token);
    JwtService.saveExpiresAt(tokens.value.expires_at);
  };

  const getUser = async (forceValidation = false) => {
    Logger.debug(`useUser/${id}/user`);

    await verifyRefreshToken(forceValidation);

    /* At this stage the user is validated or logged-out */
    if (
      tokens.value?.access_token &&
      user.value?.data?.email &&
      !forceValidation
    ) {
      return;
    }

    await reducedGetUser(tokens?.value?.access_token);
    if (user.value) {
      localStorage.setItem("user", JSON.stringify(user.value));
    }
  };

  const login = async (credentials) => {
    await reducedLogin(credentials);

    if (error.value !== null) {
      return;
    }

    JwtService.saveToken(tokens.value?.access_token);
    JwtService.saveRefreshToken(tokens.value?.refresh_token);
    JwtService.saveExpiresAt(tokens.value?.expires_at);

    await getUser();

    /* If afterall we don't have an user setted up, try to login again */
    if (!user.value) {
      logout();
    }
  };

  const requestPasswordReset = async (params) => {
    return await reducedRequestPasswordReset(params);
  };

  const resetPassword = async (params) => {
    return await reducedResetPassword(params);
  };

  const register = async (params, newsletter) => {
    await reducedRegister(params, newsletter);

    if (error.value !== null) {
      return;
    }

    JwtService.saveToken(tokens.value.access_token);
    JwtService.saveRefreshToken(tokens.value.refresh_token);
    JwtService.saveExpiresAt(tokens.value.expires_at);

    await getUser();
  };

  const checkUserExistsByEmail = async (email) => {
    return await reducedCheckUserExistsByEmail(email);
  };

  const resetExists = () => {
    return reducedResetExists();
  };

  return {
    /* Attributes */
    isAuthenticated: computed(() => isAuthenticated.value),
    userExistsByEmail: computed(() => userExistsByEmail.value),
    user: computed(() => user.value),
    username: computed(
      () => user.value.data.first_name + " " + user.value.data.last_name,
    ),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    resetPasswordForm: computed(() => resetPasswordForm.value),

    // initials: computed(() => {
    //   if (user.value === null || user.value.data === null) {
    //     return '';
    //   }
    //
    //   if (user.value.data.first_name.length > 0) {
    //     if (user.value.data.last_name.length > 0) {
    //       return `${user.value.data.first_name
    //         .charAt(0)
    //         .toUpperCase()}${user.value.data.last_name
    //         .charAt(0)
    //         .toUpperCase()}`;
    //     }
    //
    //     return user.value.data.first_name.charAt(0).toUpperCase();
    //   }
    //
    //   return '';
    // }),

    /* Methods */
    getUser,
    login,
    register,
    logout,
    checkUserExistsByEmail,
    resetExists,
    resetPassword,
    updateCustomer,
    updatePassword,
    requestPasswordReset,
    verifyRefreshTokenManually,
  };
};
