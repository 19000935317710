import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0def33e9 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _d0a487ee = () => interopDefault(import('../pages/account/general-info.vue' /* webpackChunkName: "pages/account/general-info" */))
const _09107dfb = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _155fbbb8 = () => interopDefault(import('../pages/account/referral-info.vue' /* webpackChunkName: "pages/account/referral-info" */))
const _eba0df6c = () => interopDefault(import('../pages/account/user-info.vue' /* webpackChunkName: "pages/account/user-info" */))
const _6e8e688c = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _6f0eb532 = () => interopDefault(import('../pages/checkout/index.js' /* webpackChunkName: "pages/checkout/index" */))
const _64a92492 = () => interopDefault(import('../pages/checkout/order-summary.vue' /* webpackChunkName: "pages/checkout/order-summary" */))
const _744ba531 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _8b64a4cc = () => interopDefault(import('../pages/checkout/personal-details.vue' /* webpackChunkName: "pages/checkout/personal-details" */))
const _ff09ccb2 = () => interopDefault(import('../pages/event-list-page.vue' /* webpackChunkName: "pages/event-list-page" */))
const _32650a52 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f994279a = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _215c6a30 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _e10d59f0 = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _4b04a16f = () => interopDefault(import('../pages/magazine/article.vue' /* webpackChunkName: "pages/magazine/article" */))
const _4917cfd5 = () => interopDefault(import('../pages/magazine/category.vue' /* webpackChunkName: "pages/magazine/category" */))
const _357fd59e = () => interopDefault(import('../pages/magazine/magazine-wrapper.vue' /* webpackChunkName: "pages/magazine/magazine-wrapper" */))
const _9cb55080 = () => interopDefault(import('../pages/order-success.vue' /* webpackChunkName: "pages/order-success" */))
const _65387487 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _1e713191 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _2809895a = () => interopDefault(import('../pages/ping-default.vue' /* webpackChunkName: "pages/ping-default" */))
const _67389d00 = () => interopDefault(import('../pages/ping-optimized.vue' /* webpackChunkName: "pages/ping-optimized" */))
const _63c45034 = () => interopDefault(import('../pages/ping.vue' /* webpackChunkName: "pages/ping" */))
const _625588d3 = () => interopDefault(import('../pages/root-domain/home.vue' /* webpackChunkName: "" */))
const _ba5a2bc8 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4be2d271 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _64601b84 = () => interopDefault(import('../pages/stripe-3d-auth-complete.vue' /* webpackChunkName: "pages/stripe-3d-auth-complete" */))
const _5f4cf8f9 = () => interopDefault(import('../pages/sub-domain/home.vue' /* webpackChunkName: "" */))
const _6a896adc = () => interopDefault(import('../pages/teamevent/index.vue' /* webpackChunkName: "pages/teamevent/index" */))
const _58f2fee8 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2dec0274 = () => interopDefault(import('../pages/terms-supplier.vue' /* webpackChunkName: "pages/terms-supplier" */))
const _d475c568 = () => interopDefault(import('../pages/auth/social-login.vue' /* webpackChunkName: "pages/auth/social-login" */))
const _6f044fa6 = () => interopDefault(import('../pages/city-category/city-category.vue' /* webpackChunkName: "pages/city-category/city-category" */))
const _0401bd70 = () => interopDefault(import('../pages/embedded/booking.vue' /* webpackChunkName: "pages/embedded/booking" */))
const _ef9701da = () => interopDefault(import('../pages/embedded/button.vue' /* webpackChunkName: "pages/embedded/button" */))
const _2041fd1b = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _4780fa48 = () => interopDefault(import('../pages/partner/booking-solution.vue' /* webpackChunkName: "pages/partner/booking-solution" */))
const _44ad3e3b = () => interopDefault(import('../pages/partner/pricing.vue' /* webpackChunkName: "pages/partner/pricing" */))
const _5a86f9b4 = () => interopDefault(import('../pages/root-domain/giftcard.vue' /* webpackChunkName: "pages/root-domain/giftcard" */))
const _c7ec4f2a = () => interopDefault(import('../pages/special-giftcard/special-giftcard.vue' /* webpackChunkName: "pages/special-giftcard/special-giftcard" */))
const _305de6da = () => interopDefault(import('../pages/sub-domain/giftcard.vue' /* webpackChunkName: "pages/sub-domain/giftcard" */))
const _a9e315b2 = () => interopDefault(import('../pages/t/general-event-list-page.vue' /* webpackChunkName: "pages/t/general-event-list-page" */))
const _dd7f8f90 = () => interopDefault(import('../pages/c/_categorySlug.vue' /* webpackChunkName: "pages/c/_categorySlug" */))
const _098e3c1a = () => interopDefault(import('../pages/city/_city.vue' /* webpackChunkName: "pages/city/_city" */))
const _79bd3179 = () => interopDefault(import('../pages/e/_event.vue' /* webpackChunkName: "pages/e/_event" */))
const _5fef1009 = () => interopDefault(import('../pages/e/_event/index.ts' /* webpackChunkName: "pages/e/_event/index" */))
const _7b11884c = () => interopDefault(import('../pages/e/_event/BasicDetails.vue' /* webpackChunkName: "pages/e/_event/BasicDetails" */))
const _2cbd4108 = () => interopDefault(import('../pages/e/_event/EventBreadcrumbs.vue' /* webpackChunkName: "pages/e/_event/EventBreadcrumbs" */))
const _96a85f8a = () => interopDefault(import('../pages/e/_event/EventIconList.vue' /* webpackChunkName: "pages/e/_event/EventIconList" */))
const _9d4b04fe = () => interopDefault(import('../pages/e/_event/EventIconList/EventIconListTable.vue' /* webpackChunkName: "pages/e/_event/EventIconList/EventIconListTable" */))
const _816f79c8 = () => interopDefault(import('../pages/e/_event/Gallery.vue' /* webpackChunkName: "pages/e/_event/Gallery" */))
const _125e8740 = () => interopDefault(import('../pages/e/_event/LocationAndMap.vue' /* webpackChunkName: "pages/e/_event/LocationAndMap" */))
const _03db4a4b = () => interopDefault(import('../pages/e/_event/PrivateEventInfo.vue' /* webpackChunkName: "pages/e/_event/PrivateEventInfo" */))
const _fbedc66a = () => interopDefault(import('../pages/e/_event/ReviewShowcase.vue' /* webpackChunkName: "pages/e/_event/ReviewShowcase" */))
const _27795d27 = () => interopDefault(import('../pages/e/_event/composables/useEventBreadcrumbs.ts' /* webpackChunkName: "pages/e/_event/composables/useEventBreadcrumbs" */))
const _2897b39f = () => interopDefault(import('../pages/e/_event/composables/useKftBuyBox.ts' /* webpackChunkName: "pages/e/_event/composables/useKftBuyBox" */))
const _fbdf9342 = () => interopDefault(import('../pages/p/_permalink.vue' /* webpackChunkName: "pages/p/_permalink" */))
const _644d6197 = () => interopDefault(import('../pages/preview/_previewPermalink.vue' /* webpackChunkName: "pages/preview/_previewPermalink" */))
const _6f87a06e = () => interopDefault(import('../pages/review/_event.vue' /* webpackChunkName: "pages/review/_event" */))
const _4b561854 = () => interopDefault(import('../pages/teamevent/_city.vue' /* webpackChunkName: "pages/teamevent/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de-de/account/",
    component: _0def33e9,
    pathToRegexpOptions: {"strict":true},
    name: "account___de-de",
    children: [{
      path: "general-info/",
      component: _d0a487ee,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___de-de"
    }, {
      path: "orders/",
      component: _09107dfb,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___de-de"
    }, {
      path: "referral-info/",
      component: _155fbbb8,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___de-de"
    }, {
      path: "user-info/",
      component: _eba0df6c,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___de-de"
    }]
  }, {
    path: "/de-de/checkout/",
    component: _6e8e688c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6f0eb532,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___de-de"
    }, {
      path: "order-summary/",
      component: _64a92492,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___de-de"
    }, {
      path: "payment/",
      component: _744ba531,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___de-de"
    }, {
      path: "personal-details/",
      component: _8b64a4cc,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___de-de"
    }]
  }, {
    path: "/de-de/event-list-page/",
    component: _ff09ccb2,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___de-de"
  }, {
    path: "/de-de/faq/",
    component: _32650a52,
    pathToRegexpOptions: {"strict":true},
    name: "faq___de-de"
  }, {
    path: "/de-de/impressum/",
    component: _f994279a,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___de-de"
  }, {
    path: "/de-de/login/",
    component: _215c6a30,
    pathToRegexpOptions: {"strict":true},
    name: "login___de-de"
  }, {
    path: "/de-de/magazine/",
    component: _e10d59f0,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___de-de",
    children: [{
      path: "article/",
      component: _4b04a16f,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___de-de"
    }, {
      path: "category/",
      component: _4917cfd5,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___de-de"
    }, {
      path: "magazine-wrapper/",
      component: _357fd59e,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___de-de"
    }]
  }, {
    path: "/de-de/order-success/",
    component: _9cb55080,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___de-de"
  }, {
    path: "/de-de/partner/",
    component: _65387487,
    pathToRegexpOptions: {"strict":true},
    name: "partner___de-de"
  }, {
    path: "/de-de/password-reset/",
    component: _1e713191,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___de-de"
  }, {
    path: "/de-de/ping-default/",
    component: _2809895a,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___de-de"
  }, {
    path: "/de-de/ping-optimized/",
    component: _67389d00,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___de-de"
  }, {
    path: "/de-de/ping/",
    component: _63c45034,
    pathToRegexpOptions: {"strict":true},
    name: "ping___de-de"
  }, {
    path: "/de-de/root-domain/",
    component: _625588d3,
    props: true,
    name: "home___de-de"
  }, {
    path: "/de-de/search/",
    component: _ba5a2bc8,
    pathToRegexpOptions: {"strict":true},
    name: "search___de-de"
  }, {
    path: "/de-de/sitemap/",
    component: _4be2d271,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___de-de"
  }, {
    path: "/de-de/stripe-3d-auth-complete/",
    component: _64601b84,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___de-de"
  }, {
    path: "/de-de/sub-domain/",
    component: _5f4cf8f9,
    props: true,
    name: "supplier-home___de-de"
  }, {
    path: "/de-de/teamevent/",
    component: _6a896adc,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___de-de"
  }, {
    path: "/de-de/terms-and-conditions/",
    component: _58f2fee8,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___de-de"
  }, {
    path: "/de-de/terms-supplier/",
    component: _2dec0274,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___de-de"
  }, {
    path: "/en-us/account/",
    component: _0def33e9,
    pathToRegexpOptions: {"strict":true},
    name: "account___en-us",
    children: [{
      path: "general-info/",
      component: _d0a487ee,
      pathToRegexpOptions: {"strict":true},
      name: "account-general-info___en-us"
    }, {
      path: "orders/",
      component: _09107dfb,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders___en-us"
    }, {
      path: "referral-info/",
      component: _155fbbb8,
      pathToRegexpOptions: {"strict":true},
      name: "account-referral-info___en-us"
    }, {
      path: "user-info/",
      component: _eba0df6c,
      pathToRegexpOptions: {"strict":true},
      name: "account-user-info___en-us"
    }]
  }, {
    path: "/en-us/checkout/",
    component: _6e8e688c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6f0eb532,
      pathToRegexpOptions: {"strict":true},
      name: "checkout___en-us"
    }, {
      path: "order-summary/",
      component: _64a92492,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-order-summary___en-us"
    }, {
      path: "payment/",
      component: _744ba531,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-payment___en-us"
    }, {
      path: "personal-details/",
      component: _8b64a4cc,
      pathToRegexpOptions: {"strict":true},
      name: "checkout-personal-details___en-us"
    }]
  }, {
    path: "/en-us/event-list-page/",
    component: _ff09ccb2,
    pathToRegexpOptions: {"strict":true},
    name: "event-list-page___en-us"
  }, {
    path: "/en-us/faq/",
    component: _32650a52,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en-us"
  }, {
    path: "/en-us/impressum/",
    component: _f994279a,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___en-us"
  }, {
    path: "/en-us/login/",
    component: _215c6a30,
    pathToRegexpOptions: {"strict":true},
    name: "login___en-us"
  }, {
    path: "/en-us/magazine/",
    component: _e10d59f0,
    pathToRegexpOptions: {"strict":true},
    name: "magazine___en-us",
    children: [{
      path: "article/",
      component: _4b04a16f,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-article___en-us"
    }, {
      path: "category/",
      component: _4917cfd5,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-category___en-us"
    }, {
      path: "magazine-wrapper/",
      component: _357fd59e,
      pathToRegexpOptions: {"strict":true},
      name: "magazine-magazine-wrapper___en-us"
    }]
  }, {
    path: "/en-us/order-success/",
    component: _9cb55080,
    pathToRegexpOptions: {"strict":true},
    name: "order-success___en-us"
  }, {
    path: "/en-us/partner/",
    component: _65387487,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en-us"
  }, {
    path: "/en-us/password-reset/",
    component: _1e713191,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset___en-us"
  }, {
    path: "/en-us/ping-default/",
    component: _2809895a,
    pathToRegexpOptions: {"strict":true},
    name: "ping-default___en-us"
  }, {
    path: "/en-us/ping-optimized/",
    component: _67389d00,
    pathToRegexpOptions: {"strict":true},
    name: "ping-optimized___en-us"
  }, {
    path: "/en-us/ping/",
    component: _63c45034,
    pathToRegexpOptions: {"strict":true},
    name: "ping___en-us"
  }, {
    path: "/en-us/root-domain/",
    component: _625588d3,
    props: true,
    name: "home___en-us"
  }, {
    path: "/en-us/search/",
    component: _ba5a2bc8,
    pathToRegexpOptions: {"strict":true},
    name: "search___en-us"
  }, {
    path: "/en-us/sitemap/",
    component: _4be2d271,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___en-us"
  }, {
    path: "/en-us/stripe-3d-auth-complete/",
    component: _64601b84,
    pathToRegexpOptions: {"strict":true},
    name: "stripe-3d-auth-complete___en-us"
  }, {
    path: "/en-us/sub-domain/",
    component: _5f4cf8f9,
    props: true,
    name: "supplier-home___en-us"
  }, {
    path: "/en-us/teamevent/",
    component: _6a896adc,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent___en-us"
  }, {
    path: "/en-us/terms-and-conditions/",
    component: _58f2fee8,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en-us"
  }, {
    path: "/en-us/terms-supplier/",
    component: _2dec0274,
    pathToRegexpOptions: {"strict":true},
    name: "terms-supplier___en-us"
  }, {
    path: "/de-de/auth/social-login/",
    component: _d475c568,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___de-de"
  }, {
    path: "/de-de/city-category/city-category/",
    component: _6f044fa6,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___de-de"
  }, {
    path: "/de-de/embedded/booking/",
    component: _0401bd70,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___de-de"
  }, {
    path: "/de-de/embedded/button/",
    component: _ef9701da,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___de-de"
  }, {
    path: "/de-de/login/forgot-password/",
    component: _2041fd1b,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___de-de"
  }, {
    path: "/de-de/partner/booking-solution/",
    component: _4780fa48,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___de-de"
  }, {
    path: "/de-de/partner/pricing/",
    component: _44ad3e3b,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _5a86f9b4,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___de-de"
  }, {
    path: "/de-de/root-domain/giftcard/",
    component: _5a86f9b4,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___de-de"
  }, {
    path: "/de-de/root-domain/home/",
    component: _625588d3,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___de-de"
  }, {
    path: "/de-de/special-giftcard/special-giftcard/",
    component: _c7ec4f2a,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _305de6da,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___de-de"
  }, {
    path: "/de-de/sub-domain/giftcard/",
    component: _305de6da,
    props: true,
    name: "supplier-giftcard-page___de-de"
  }, {
    path: "/de-de/sub-domain/home/",
    component: _5f4cf8f9,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___de-de"
  }, {
    path: "/de-de/t/general-event-list-page/",
    component: _a9e315b2,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___de-de"
  }, {
    path: "/en-us/auth/social-login/",
    component: _d475c568,
    pathToRegexpOptions: {"strict":true},
    name: "auth-social-login___en-us"
  }, {
    path: "/en-us/city-category/city-category/",
    component: _6f044fa6,
    pathToRegexpOptions: {"strict":true},
    name: "city-category-city-category___en-us"
  }, {
    path: "/en-us/embedded/booking/",
    component: _0401bd70,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-booking___en-us"
  }, {
    path: "/en-us/embedded/button/",
    component: _ef9701da,
    pathToRegexpOptions: {"strict":true},
    name: "embedded-button___en-us"
  }, {
    path: "/en-us/login/forgot-password/",
    component: _2041fd1b,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password___en-us"
  }, {
    path: "/en-us/partner/booking-solution/",
    component: _4780fa48,
    pathToRegexpOptions: {"strict":true},
    name: "partner-booking-solution___en-us"
  }, {
    path: "/en-us/partner/pricing/",
    component: _44ad3e3b,
    pathToRegexpOptions: {"strict":true},
    name: "partner-pricing___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _5a86f9b4,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-giftcard___en-us"
  }, {
    path: "/en-us/root-domain/giftcard/",
    component: _5a86f9b4,
    meta: {"contentGroup":"giftcard"},
    props: true,
    name: "gifcard-page___en-us"
  }, {
    path: "/en-us/root-domain/home/",
    component: _625588d3,
    pathToRegexpOptions: {"strict":true},
    name: "root-domain-home___en-us"
  }, {
    path: "/en-us/special-giftcard/special-giftcard/",
    component: _c7ec4f2a,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _305de6da,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-giftcard___en-us"
  }, {
    path: "/en-us/sub-domain/giftcard/",
    component: _305de6da,
    props: true,
    name: "supplier-giftcard-page___en-us"
  }, {
    path: "/en-us/sub-domain/home/",
    component: _5f4cf8f9,
    pathToRegexpOptions: {"strict":true},
    name: "sub-domain-home___en-us"
  }, {
    path: "/en-us/t/general-event-list-page/",
    component: _a9e315b2,
    pathToRegexpOptions: {"strict":true},
    name: "t-general-event-list-page___en-us"
  }, {
    path: "/de-de/auth/facebook/callback/",
    component: _d475c568,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___de-de"
  }, {
    path: "/de-de/auth/google/callback/",
    component: _d475c568,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___de-de"
  }, {
    path: "/en-us/auth/facebook/callback/",
    component: _d475c568,
    meta: {"socialLoginType":"facebook"},
    name: "social-login-facebook___en-us"
  }, {
    path: "/en-us/auth/google/callback/",
    component: _d475c568,
    meta: {"socialLoginType":"google"},
    name: "social-login-google___en-us"
  }, {
    path: "/de-de/c/:categorySlug?/",
    component: _dd7f8f90,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___de-de"
  }, {
    path: "/de-de/city/:city?/",
    component: _098e3c1a,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___de-de"
  }, {
    path: "/de-de/e/:event?/",
    component: _79bd3179,
    pathToRegexpOptions: {"strict":true},
    name: "event___de-de",
    children: [{
      path: "",
      component: _5fef1009,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___de-de"
    }, {
      path: "BasicDetails/",
      component: _7b11884c,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___de-de"
    }, {
      path: "EventBreadcrumbs/",
      component: _2cbd4108,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___de-de"
    }, {
      path: "EventIconList/",
      component: _96a85f8a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___de-de",
      children: [{
        path: "EventIconListTable/",
        component: _9d4b04fe,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___de-de"
      }]
    }, {
      path: "Gallery/",
      component: _816f79c8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___de-de"
    }, {
      path: "LocationAndMap/",
      component: _125e8740,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___de-de"
    }, {
      path: "PrivateEventInfo/",
      component: _03db4a4b,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___de-de"
    }, {
      path: "ReviewShowcase/",
      component: _fbedc66a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___de-de"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _27795d27,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___de-de"
    }, {
      path: "composables/useKftBuyBox/",
      component: _2897b39f,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___de-de"
    }]
  }, {
    path: "/de-de/e/:event/",
    component: _79bd3179,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _c7ec4f2a,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/giftcard/:event/",
    component: _c7ec4f2a,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___de-de"
  }, {
    path: "/de-de/magazine/:slug/",
    component: _357fd59e,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___de-de"
  }, {
    path: "/de-de/p/:permalink?/",
    component: _fbdf9342,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _fbdf9342,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/p/:permalink/",
    component: _fbdf9342,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___de-de"
  }, {
    path: "/de-de/preview/:previewPermalink?/",
    component: _644d6197,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___de-de"
  }, {
    path: "/de-de/review/:event?/",
    component: _6f87a06e,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _a9e315b2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/t/:slug/",
    component: _a9e315b2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _4b561854,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _4b561854,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/de-de/teamevent/:city/",
    component: _4b561854,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___de-de"
  }, {
    path: "/en-us/c/:categorySlug?/",
    component: _dd7f8f90,
    pathToRegexpOptions: {"strict":true},
    name: "c-categorySlug___en-us"
  }, {
    path: "/en-us/city/:city?/",
    component: _098e3c1a,
    pathToRegexpOptions: {"strict":true},
    name: "city-city___en-us"
  }, {
    path: "/en-us/e/:event?/",
    component: _79bd3179,
    pathToRegexpOptions: {"strict":true},
    name: "event___en-us",
    children: [{
      path: "",
      component: _5fef1009,
      pathToRegexpOptions: {"strict":true},
      name: "e-event___en-us"
    }, {
      path: "BasicDetails/",
      component: _7b11884c,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-BasicDetails___en-us"
    }, {
      path: "EventBreadcrumbs/",
      component: _2cbd4108,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventBreadcrumbs___en-us"
    }, {
      path: "EventIconList/",
      component: _96a85f8a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-EventIconList___en-us",
      children: [{
        path: "EventIconListTable/",
        component: _9d4b04fe,
        pathToRegexpOptions: {"strict":true},
        name: "e-event-EventIconList-EventIconListTable___en-us"
      }]
    }, {
      path: "Gallery/",
      component: _816f79c8,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-Gallery___en-us"
    }, {
      path: "LocationAndMap/",
      component: _125e8740,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-LocationAndMap___en-us"
    }, {
      path: "PrivateEventInfo/",
      component: _03db4a4b,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-PrivateEventInfo___en-us"
    }, {
      path: "ReviewShowcase/",
      component: _fbedc66a,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-ReviewShowcase___en-us"
    }, {
      path: "composables/useEventBreadcrumbs/",
      component: _27795d27,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useEventBreadcrumbs___en-us"
    }, {
      path: "composables/useKftBuyBox/",
      component: _2897b39f,
      pathToRegexpOptions: {"strict":true},
      name: "e-event-composables-useKftBuyBox___en-us"
    }]
  }, {
    path: "/en-us/e/:event/",
    component: _79bd3179,
    meta: {"contentGroup":"event"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "event-page___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _c7ec4f2a,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/giftcard/:event/",
    component: _c7ec4f2a,
    meta: {"contentGroup":"giftcard"},
    props: true,
    pathToRegexpOptions: {"strict":true},
    name: "special-giftcards___en-us"
  }, {
    path: "/en-us/magazine/:slug/",
    component: _357fd59e,
    meta: {"contentGroup":"magazine"},
    props: true,
    name: "magazine-pages___en-us"
  }, {
    path: "/en-us/p/:permalink?/",
    component: _fbdf9342,
    pathToRegexpOptions: {"strict":true},
    name: "p-permalink___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _fbdf9342,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/p/:permalink/",
    component: _fbdf9342,
    meta: {"contentGroup":"partner"},
    props: true,
    name: "supplier-profile___en-us"
  }, {
    path: "/en-us/preview/:previewPermalink?/",
    component: _644d6197,
    pathToRegexpOptions: {"strict":true},
    name: "preview-previewPermalink___en-us"
  }, {
    path: "/en-us/review/:event?/",
    component: _6f87a06e,
    pathToRegexpOptions: {"strict":true},
    name: "review-event___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _a9e315b2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/t/:slug/",
    component: _a9e315b2,
    meta: {"contentGroup":"tag"},
    props: true,
    name: "general-event-list-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _4b561854,
    pathToRegexpOptions: {"strict":true},
    name: "teamevent-city___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _4b561854,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/en-us/teamevent/:city/",
    component: _4b561854,
    meta: {"contentGroup":"team event"},
    props: true,
    name: "team-event-page___en-us"
  }, {
    path: "/de-de/:citySlug/",
    component: _098e3c1a,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/de-de/:citySlug/",
    component: _098e3c1a,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___de-de"
  }, {
    path: "/en-us/:citySlug/",
    component: _098e3c1a,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/en-us/:citySlug/",
    component: _098e3c1a,
    meta: {"contentGroup":"city"},
    props: true,
    name: "city-page___en-us"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _6f044fa6,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/de-de/:citySlug/:categorySlug/",
    component: _6f044fa6,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___de-de"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _6f044fa6,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }, {
    path: "/en-us/:citySlug/:categorySlug/",
    component: _6f044fa6,
    meta: {"contentGroup":"city category"},
    props: true,
    name: "city-categories-page___en-us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
