import { replaceSubdomainToProd } from "../helpers/mediaHelpers";
import { supplierGetters } from "./supplierGetters";

require("dayjs/locale/de");
require("dayjs/locale/en");

const getId = (event): string => {
  return event?.id || "";
};

const getNextDateAvailable = (event): string | null => {
  return event?.next_instance?.start || null;
};

const getPermalink = (event): string => {
  return event?.permalink || "";
};

const getNextDateAvailableEnd = (event): string => {
  return event?.next_instance?.end || "";
};

const getNextDateAvailableId = (event): string => {
  return event?.next_instance?.id || "";
};

const getNextDateAvailableDuration = (event): string => {
  return event?.next_instance?.duration || null;
};

const getEventMinimumDuration = (event): number => {
  return event?.min_duration || null;
};

const getEventMaximumDuration = (event): number => {
  return event?.max_duration || null;
};

const getTitle = (event): string => {
  return event?.title || "";
};

const getDescription = (event): string => {
  return event?.description || "";
};

const getShortDescription = (event): string => {
  return event?.description_summary || "";
};

const getSubtitle = (event): string => {
  return event?.subtitle || "";
};

const getTags = (event): Array<any> => {
  return event?.tags?.data || [];
};

const getDefaultDuration = (event): string => {
  return event?.default_duration || "";
};

const getWhatIsIncludedSummary = (event): string => {
  return event?.what_is_included_summary || "";
};

const getTeamEventsAndGiftCardsSummary = (event): string => {
  return event?.team_events_and_gift_cards_summary || "";
};

const getCategories = (event): string | null => {
  return event?.categories?.data || "";
};

const getMainCategory = (event): string | null => {
  const categories = event?.categories?.data;
  if (categories && categories.length) {
    return categories[0].name || null;
  }

  return null;
};

const getMainCategorySlug = (event): string | null => {
  const categories = event?.categories?.data;
  if (categories && categories.length) {
    return categories[0].slug || null;
  }

  return null;
};

const getLocationSummary = (event): string => {
  return event?.location_summary || "";
};

const getHasMultipleLocations = (event): boolean => {
  return event?.multiple_locations || false;
};

const getWhatToBring = (event): string => {
  return event?.what_to_bring || "";
};

const getPrivateEventDescription = (event): string => {
  return event?.private_event_description || "";
};

const getWhatIsIncluded = (event): string => {
  return event?.what_is_included || "";
};

const getSupplier = (event): any => {
  return event?.supplier?.data || null;
};

const getSupplierLogo = (event): string => {
  return supplierGetters.getLogo(getSupplier(event));
};

const getSupplierId = (event) => {
  return getSupplier(event)?.id;
};

const getSupplierCreatedAt = (event): boolean => {
  return supplierGetters.getCreatedAt(getSupplier(event));
};
const getSupplierDescription = (event): string => {
  return supplierGetters.getDescription(getSupplier(event));
};

const getSupplierShortDescription = (event): string => {
  return supplierGetters.getShortDescription(getSupplier(event));
};

const getSupplierName = (event): string => {
  return supplierGetters.getName(getSupplier(event));
};

const getSupplierUserName = (event): string => {
  return supplierGetters.getUserName(getSupplier(event));
};

const getSupplierPermalink = (event): string => {
  return supplierGetters.getPermalink(getSupplier(event));
};

const getSupplierWebsite = (event): string => {
  return supplierGetters.getWebsite(getSupplier(event));
};

const getEventSupplierSubdomain = (event): string => {
  return supplierGetters.getSubdomain(getSupplier(event));
};

const getNextInstanceInTimestamp = (event): number => {
  const toTimestamp = new Date(event.next_instance?.start).getTime();
  return Math.floor(toTimestamp / 1000) || null;
};

const getCoordinates = (event): any => {
  return event?.address?.data?.coordinate || null;
};

const getCoordinatesString = (event) => getCoordinates(event)?.join(", ");

const getFormattedAddress = (event): string => {
  return event?.address?.data?.formatted || "";
};

const getAddressZipcode = (event): string => {
  return event?.address?.data?.zipcode || "";
};

const getAddressLocality = (event): any => {
  return event?.address?.data?.locality?.data || {};
};

const getAddressLocalityName = (event): string => {
  return event?.address?.data?.locality?.data.name || "";
};

const getAddressLocalitySlug = (event): string => {
  return event?.address?.data?.locality?.data.slug || "";
};

const getIsAddressRequired = (event): boolean => {
  return event.required_address || false;
};

const getMinimumPrice = (event, from): string | number | null => {
  return event?.min_price ? from + event?.min_price?.formatted || null : null;
};

const getMinimumPriceAmount = (event): string | number | null => {
  return event?.min_price?.amount || null;
};

const getMinimumPriceCurrency = (event): string | number | null => {
  return event?.min_price?.currency || null;
};

const getMaximumPrice = (event, from): string | number | null => {
  return event?.max_price ? from + event?.max_price?.formatted || null : null;
};

const getMaximumPriceAmount = (event): string | number | null => {
  return event?.max_price?.amount || null;
};

const getMaximumPriceCurrency = (event): string | number | null => {
  return event?.max_price?.currency || null;
};

const getPrice = (product): string | number | null => {
  return product?.price?.formatted || null;
};

const getPriceAmount = (product): number | null => {
  return product?.price?.amount || null;
};

const getTimezone = (event) => {
  return event?.timezone || null;
};

const getDefaultFormattedPrice = (event): string | number | null => {
  return event?.default_price?.formatted || null;
};

const getDefaultPriceAmount = (event): string | number | null => {
  return event?.default_price?.amount || null;
};

const getDefaultPriceObject = (event): string | number | null => {
  return event?.default_price || null;
};

type GalleryItem = {
  id: number;
  filename: string;
  original: string;
  thumb: string;
  cropped: string;
  dimensions: {
    width: number;
    height: number;
  };
  social_cropped: null | string | boolean;
  media_type: "photo" | "video";
  orientation: "landscape" | "portrait" | "square";
  isPrincipal: boolean;
  isFeatured: boolean;
  alt: string;
};

const getGallery = (event): Array<any> =>
  event?.photos?.map((photo) => ({
    ...photo,
    cropped: replaceSubdomainToProd(photo?.cropped),
    original: replaceSubdomainToProd(photo?.original),
    thumb: replaceSubdomainToProd(photo?.thumb),
  })) || [];

const getGalleryImages = (event): any[] => {
  const gallery = eventGetters.getGallery(event);
  return gallery?.filter((image) => image?.media_type === "photo");
};

const getPrincipalImage = (event): any => {
  const gallery = eventGetters.getGalleryImages(event);
  return gallery?.find((x) => x.isPrincipal);
};

const getFeaturedImages = (event): any[] => {
  const gallery = eventGetters.getGalleryImages(event);
  return gallery?.filter((x) => x.isFeatured) || [];
};

const getNonFeaturedImages = (event): any[] => {
  const gallery = eventGetters.getGalleryImages(event);
  return gallery?.filter((x) => !x.isFeatured && !x.isPrincipal) || [];
};

const getLandscapeVideo = (event): GalleryItem | null => {
  const gallery = eventGetters.getGallery(event);
  return (
    gallery?.find(
      (x) => x.media_type === "video" && x.orientation === "landscape",
    ) || null
  );
};

const getPortraitVideo = (event): GalleryItem | null => {
  const gallery = eventGetters.getGallery(event);
  return (
    gallery?.find(
      (x) => x.media_type === "video" && x.orientation === "portrait",
    ) || null
  );
};

/* @deprecated */
const getFirstThumb = (event): string => {
  const photos = event?.photos;
  return photos && photos.length && photos.length > 0
    ? event.photos[0]?.thumb
    : "";
};

const getAttendanceMode = (event): string => {
  return event?.type || "";
};

const getIsTeamEvent = (event): boolean => {
  return event?.is_team_event || false;
};

const getContentLocale = (event): string => {
  return event?.content_locale || "";
};

const getMetaDescription = (event): string => {
  return event?.meta_description || "";
};
const getLanguages = (event): string[] => {
  return event?.languages || null;
};

const getReviewsAverage = (event, locale = "de-DE"): string | null => {
  const avg = getReviewsRating(event);

  if (avg) {
    return new Intl.NumberFormat(locale, {
      maximumSignificantDigits: 2,
      minimumSignificantDigits: 2,
    }).format(avg);
  }

  return null;
};

const getReviewsRating = (event): number | null => {
  return event?.avg_review || null;
};

const isGiftcardEvent = (event): boolean =>
  event?.supplier?.data?.name === "konfetti Gutscheine";

const getReviewsTotal = (event): number => {
  return event?.total_reviews || 0;
};

const getReviewsList = (event): any[] => {
  return event?.reviewsNew?.data || [];
};

// Reviews list sorted by Recency first (created_at decreasing)
const getReviewsListRecentFirst = (event): any[] => {
  return getReviewsList(event)
    .slice()
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
};

const getIsApproved = (event): boolean => {
  return event?.approved || false;
};

const getStatus = (event): string => {
  return event?.status || "";
};

const getEventIdFromPath = (path: string): string =>
  path?.split("-").pop() || "";

const isWaitlistActive = (event): boolean =>
  event?.waitlist_active === 1 || false;

const isDateRequestActive = (event): boolean =>
  event?.request_active === 1 || false;

const isDateRequestBookingSolution = (event): boolean =>
  event?.request_booking_solution === 1 || false;

/* isDateRequest Active on Booking solution */
const isDateRequestActiveBS = (event, isSubdomain = false): boolean =>
  isSubdomain
    ? isDateRequestActive(event) && isDateRequestBookingSolution(event)
    : isDateRequestActive(event);

const isPrivateDateRequestActive = (event): boolean =>
  event?.request_private === 1 || false;

const isDateRequestOrWaitingActive = (event, isSubdomain = false): boolean =>
  isDateRequestActiveBS(event, isSubdomain) || isWaitlistActive(event);

const isDateRequestActiveWithSubdomainCheck = (
  event,
  isSubdomain = false,
): boolean =>
  isDateRequestActiveBS(event, isSubdomain) || isWaitlistActive(event);

const getDateRequestMinAdvanceQuantity = (event): number =>
  event?.request_min_advance || 0;

const getDateRequestMinAdvanceUnit = (event): string =>
  event?.request_min_advance_type || "";

const getMaxDateRequestTickets = (event): number =>
  event?.request_max_tickets || 50;

const getDateRequestAvailableDays = (event): number[] =>
  event?.request_days || [];

export const eventGetters = {
  getId,
  getTitle,
  getDescription,
  getShortDescription,
  getSubtitle,
  getTags,
  getEventIdFromPath,
  getIsAddressRequired,
  getDefaultDuration,
  getWhatIsIncludedSummary,
  getCategories,
  getMainCategory,
  getMainCategorySlug,
  getTeamEventsAndGiftCardsSummary,
  getLocationSummary,
  getHasMultipleLocations,
  getWhatToBring,
  getPrivateEventDescription,
  getWhatIsIncluded,
  getSupplierCreatedAt,
  getSupplierDescription,
  getSupplierShortDescription,
  getSupplierName,
  getSupplierWebsite,
  getEventSupplierSubdomain,
  getNextInstanceInTimestamp,
  getCoordinates,
  getCoordinatesString,
  getFormattedAddress,
  getAddressZipcode,
  getAddressLocality,
  getAddressLocalityName,
  getAddressLocalitySlug,
  getMinimumPrice,
  getMinimumPriceAmount,
  getMinimumPriceCurrency,
  getMaximumPrice,
  getMaximumPriceAmount,
  getMaximumPriceCurrency,
  getGallery,
  getGalleryImages,
  getPrincipalImage,
  getFeaturedImages,
  getLandscapeVideo,
  getPortraitVideo,
  getPrice,
  getNextDateAvailable,
  getNextDateAvailableEnd,
  getNextDateAvailableDuration,
  getNextDateAvailableId,
  getEventMinimumDuration,
  getFirstThumb,
  getPriceAmount,
  getAttendanceMode,
  getSupplierLogo,
  getSupplierId,
  getPermalink,
  getIsTeamEvent,
  getContentLocale,
  getDefaultFormattedPrice,
  getMetaDescription,
  getTimezone,
  getEventMaximumDuration,
  getDefaultPriceAmount,
  getDefaultPriceObject,
  getLanguages,
  getSupplier,
  getReviewsAverage,
  getReviewsRating,
  getReviewsTotal,
  getReviewsList,
  getReviewsListRecentFirst,
  getIsApproved,
  getStatus,
  getSupplierUserName,
  getSupplierPermalink,
  getNonFeaturedImages,
  isGiftcardEvent,
  isWaitlistActive,
  isDateRequestActive,
  isDateRequestBookingSolution,
  isDateRequestOrWaitingActive,
  isDateRequestActiveWithSubdomainCheck,
  isDateRequestActiveBS,
  isPrivateDateRequestActive,
  getMaxDateRequestTickets,
  getDateRequestAvailableDays,
  getDateRequestMinAdvanceQuantity,
  getDateRequestMinAdvanceUnit,
};
